import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import React, { useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { enterpriseYearlyActions } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { API } from "../../api/rest";
import { useSnackbar } from "notistack";

export const ReductionSetter: React.FC<{ enterpriseYearly: EnterpriseYearly }> =
  (props) => {
    const [reduction, setReduction] = useState(
      props.enterpriseYearly.reduction
    );
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const CurrentReduction: React.FC = () => {
      return (
        <Typography>
          L'entreprise possède actuellement une réduction de{" "}
          <b style={{ color: "green" }}>{props.enterpriseYearly.reduction}€</b>
        </Typography>
      );
    };

    const onSetReductionClicked = () => {
      const patch = {
        id: props.enterpriseYearly.id,
        reduction,
      };

      API.updateEnterpriseYearly(patch)
        .then(() => {
          dispatch(
            enterpriseYearlyActions.upsertOne({
              ...props.enterpriseYearly,
              reduction,
            })
          );
        })
        .catch(() => {
          enqueueSnackbar("Une erreur s'est produite", { variant: "error" });
        });
    };

    const SetReduction: React.FC = () => {
      return (
        <>
          <TextField
            type={"number"}
            value={reduction}
            onChange={(event) => setReduction(parseInt(event.target.value))}
            style={{ marginRight: "3rem" }}
          />
          <Button variant={"outlined"} onClick={onSetReductionClicked}>
            APPLIQUER LA RÉDUCTION
          </Button>
        </>
      );
    };

    return (
      <>
        <CurrentReduction />
        <SetReduction />
      </>
    );
  };
