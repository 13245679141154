import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { Link } from "./types";
import { STATUS } from "../../constants/rest";
import { AxiosError, AxiosResponse } from "axios";
import { API } from "../../api/rest";
import { Epoch } from "../epochs/epochsSlice";

const linksAdapter = createEntityAdapter<Link>({
  sortComparer: (a, b) => {
    if (a.creation_date < b.creation_date) {
      return 1;
    } else {
      return -1;
    }
  },
});

type State = {
  fetchStatus: string;
  error: AxiosError<any> | null | undefined;
} & EntityState<Link>;

const initialState = linksAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
}) as State;

const fetch = createAsyncThunk<Link[], undefined, { rejectValue: AxiosError }>(
  "links/fetch",
  (arg: undefined, thunkAPI) => {
    return API.fetchLinks()
      .then((response: AxiosResponse<Link>) => response.data)
      .catch((error: AxiosError<any>) => thunkAPI.rejectWithValue(error));
  }
);
export { fetch as fetchLinks };

const linksSlice = createSlice({
  name: "link",
  initialState,
  reducers: {
    addOne: linksAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state, action) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      linksAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
    });
  },
});

export const linksActions = linksSlice.actions;
export const linksSelector = linksAdapter.getSelectors(
  (state: any) => state.links
);
export const selectLinksFromEpoch = (epoch?: Epoch) => (state: any) =>
  linksSelector.selectAll(state).filter((link: Link) => {
    if (epoch) {
      return epoch.id === link.epoch;
    }
  });
export const selectLinksFetchStatus = (state: any) => state.links.fetchStatus;
export default linksSlice.reducer;
