import React, { FC, useEffect, useState } from "react";
import { Enterprise } from "../features/enterprises/types";
import { STATUS } from "../constants/rest";
import { API } from "../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import {
  Card,
  CardContent,
  CardMedia,
  TextField,
  Typography,
} from "@material-ui/core";
import { EditButton } from "./EditButton";
import { useStyles } from "../styles/ProfileStyle";
import { difference } from "../helpers/patchHelper";
import { ImageUploadField } from "./UploadButton";
import { useFetchEnterprise } from "../features/enterprises/enterprisesSlice";
import { StatusGuard } from "./Commons/StatusGuard";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { CurrencyTextField } from "../components/Commons/CurrencyTextField";

type EnterpriseProfileProps = {
  enterprise: Enterprise;
};

type LogoProps = {
  url?: string | File;
  label: string;
};
const Logo = (props: LogoProps) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (!props.url) {
      return;
    } else if (typeof props.url !== "string") {
      setUrl(URL.createObjectURL(props.url));
    } else {
      setUrl(props.url);
    }
  }, [props.url]);
  if (url) {
    return (
      <Card style={{ maxWidth: "256px", padding: "1rem" }}>
        <CardMedia
          component="img"
          alt="logo"
          image={url}
          title="Logo"
          style={{ border: "black", borderStyle: "solid", padding: "1rem" }}
        />
        <CardContent style={{ display: "flex", margin: "auto" }}>
          <Typography style={{ margin: "auto" }}>{props.label}</Typography>
        </CardContent>
      </Card>
      // <div>
      //     <Container>
      //         <img alt='logo entreprise' src={url} style={{maxWidth:"128px", maxHeight:"128px", margin:'20px'}}/>
      //     </Container>
      //     <Typography style={{marginLeft: "4.3rem"}}>{props.label}</Typography>
      // </div>
    );
  }
  return (
    <Typography style={{ marginTop: "1.5rem", marginLeft: "1rem" }}>
      Veuillez ajouter un(e) {props.label} en appuyant sur MODIFIER.
    </Typography>
  );
};

export const EnterpriseProfileWrapper: FC<{ enterpriseId: number }> = (
  props
) => {
  const fetchWrapper = useFetchEnterprise(props.enterpriseId);
  if (!fetchWrapper.data) return null;
  return (
    <StatusGuard status={fetchWrapper.fetchStatus}>
      <EnterpriseProfile enterprise={fetchWrapper.data} />
    </StatusGuard>
  );
};
export const EnterpriseProfile = (props: EnterpriseProfileProps) => {
  const [editMode, setEditMode] = useState(false);
  const [enterprise, setEnterprise] = useState(props.enterprise);
  const [updateStatus, setUpdateStatus] = useState(STATUS.IDLE);
  const [updateErrors, setUpdateErrors] = useState({} as any);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleEditButtonClicked = () => {
    setEditMode(!editMode);
  };

  const handleCancelButtonClicked = () => {
    setEditMode(!editMode);
    setEnterprise(props.enterprise);
    setUpdateErrors({});
  };

  const handleSaveButtonClicked = () => {
    if (updateStatus !== STATUS.PENDING) {
      setUpdateStatus(STATUS.PENDING);
      let delta = difference(enterprise, props.enterprise);
      delta.id = props.enterprise.id;

      API.updateEnterprise(delta)
        .then((response: AxiosResponse) => {
          setUpdateStatus(STATUS.FULFILLED);
          setEditMode(!editMode);
          setUpdateErrors({});
          enqueueSnackbar("Informations sauvegardées", { variant: "success" });
          // dispatch(enterprisesActions.upsertOne(props?.enterprise))
        })
        .catch((error: AxiosError) => {
          setUpdateStatus(STATUS.FAILED);
          setUpdateErrors(error.response?.data);
          enqueueSnackbar("Erreur lors de la sauvegarde des informations", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      <TextField
        id="outlined-margin-normal-1"
        margin="normal"
        variant="outlined"
        disabled={true}
        value={enterprise.name}
        className={classes.textField}
      />
      <br />
      <TextField
        id="outlined-margin-normal-2"
        required
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Téléphone"
        disabled={!editMode}
        value={enterprise.phone_number}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            phone_number: event.target.value,
          })
        }
        error={!!updateErrors?.phone_number}
        helperText={updateErrors?.phone_number}
      />
      <TextField
        required
        id="outlined-margin-normal-3"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Adresse de facturation"
        disabled={!editMode}
        value={enterprise.address}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            address: event.target.value,
          })
        }
        error={!!updateErrors?.address}
        helperText={updateErrors?.address}
      />
      <br />
      <TextField
        required
        id="outlined-margin-normal-4"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Code Postal"
        disabled={!editMode}
        value={enterprise.zip_code}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            zip_code: event.target.value,
          })
        }
        error={!!updateErrors?.zip_code}
        helperText={updateErrors?.zip_code}
      />
      <TextField
        required
        id="outlined-margin-normal-5"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Ville"
        disabled={!editMode}
        value={enterprise.city}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            city: event.target.value,
          })
        }
        error={!!updateErrors?.city}
        helperText={updateErrors?.city}
      />
      <br />
      <TextField
        id="outlined-margin-normal-6"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Date de création"
        disabled={!editMode}
        value={enterprise.creation_year}
        type={"number"}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            creation_year: isNaN(parseInt(event.target.value))
              ? event.target.value
              : parseInt(event.target.value),
          })
        }
        error={!!updateErrors?.creation_year}
        helperText={updateErrors?.creation_year}
      />
      <TextField
        id="outlined-margin-normal-5"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Website"
        disabled={!editMode}
        value={enterprise.website}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            website: event.target.value,
          })
        }
        error={!!updateErrors?.website}
        helperText={updateErrors?.website}
      />
      <br />
      <span className={classes.textField}>
        <ImageUploadField
          url={enterprise?.logo}
          name={"Logo"}
          label={"logo"}
          invisible={!editMode}
          onFileChange={(event: any) =>
            setEnterprise({
              ...enterprise,
              logo: event.target.files[0],
            })
          }
          error={updateErrors?.logo}
        />
      </span>

      <span className={classes.textField}>
        <ImageUploadField
          url={enterprise.picture_public}
          name={"Image Publique"}
          label={"image-public"}
          invisible={!editMode}
          onFileChange={(event: any) =>
            setEnterprise({
              ...enterprise,
              picture_public: event.target.files[0],
            })
          }
          error={updateErrors?.picture_public}
        />
      </span>

      <br />
      <TextField
        id="outlined-margin-normal-7"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Texte de présentation de l'entreprise"
        disabled={!editMode}
        value={enterprise.text_presentation}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            text_presentation: event.target.value,
          })
        }
        error={!!updateErrors?.text_presentation}
        helperText={updateErrors?.text_presentation}
        style={{ width: "95%" }}
        multiline
      />
      <TextField
        id="outlined-margin-normal-8"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Texte de présentation des stages"
        disabled={!editMode}
        value={enterprise.text_stages}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            text_stages: event.target.value,
          })
        }
        error={!!updateErrors?.text_stages}
        helperText={updateErrors?.text_stages}
        style={{ width: "95%" }}
        multiline
      />
      <br />
      <CurrencyTextField
        formControlProps={{
          className: classes.textField,
          variant: "outlined",
          disabled: !editMode,
          error: !!updateErrors?.turnover,
        }}
        label="Chiffre d'affaire"
        value={enterprise.turnover}
        helperText={updateErrors?.turnover}
        currencies={["$", "€", "¥"]}
        onCurrencyChange={(value) =>
          setEnterprise({
            ...enterprise,
            turnover_currency: value,
          })
        }
        onValueChange={(value) =>
          setEnterprise({
            ...enterprise,
            turnover: value,
          })
        }
        selectedCurrency={enterprise.turnover_currency}
      />
      <TextField
        id="outlined-margin-normal-10"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Nombre d'employés"
        disabled={!editMode}
        value={enterprise.workforce}
        type={"number"}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            workforce: parseInt(event.target.value),
          })
        }
        error={!!updateErrors?.workforce}
        helperText={updateErrors?.workforce}
      />
      <br />
      <EditButton
        editMode={editMode}
        onEdit={handleEditButtonClicked}
        onSave={handleSaveButtonClicked}
        onCancel={handleCancelButtonClicked}
      />

      <div style={{ fontStyle: "italic", padding: "20px" }}>
        Les champs marqués d'une astérisque (*) sont obligatoires pour
        poursuivre l'inscription.
        <br />
        Les autres sont facultatifs et pourront être remplis plus tard.
        <br />
        L'image publique est un fichier image qui sera affiché (en plus du logo)
        sur la page dédiée à l'entreprise sur le site.
      </div>
    </div>
  );
};
