import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { UserLoginForm } from "./features/users/UserLoginForm";
import { UserLogoutForm } from "./features/users/UserLogoutForm";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  initialize,
  selectIsInitialized,
} from "./features/initializer/initializerReducer";
import ProtectedRoute from "./components/ProtectedRoute";
import { Vitrine } from "./features/vitrine/Vitrine";
import { Footer } from "./features/footer/Footer";
import { EnterprisesGrid } from "./features/publicEnterprisesGrid/EnterprisesGrid";
import { EnterpriseYearlySinglePage } from "./features/enterprisesYearly/EnterpriseYearlySinglePage";
import { AddEnterpriseForm } from "./features/enterprises/AddEnterpriseForm";
import { AddLinkForm } from "./features/links/AddLinkForm";
import { EpochDashboard } from "./features/epochs/EpochDashboard";
import { AccountCreationForm } from "./features/contacts/AccountCreationForm";
import { UserDashboardWrapped } from "./components/UserDashboard/UserDashboard";
import { PublicPage } from "./features/entPublicPage/EntPublicPage";
import { Dashboard } from "./components/Dashboard";
import { NotFoundPage } from "./components/Commons/NotFoundPage";

function App() {
  const dispatch = useDispatch();
  const isInitialized = useSelector(selectIsInitialized);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(initialize());
    }
  });

  let content = <div>Initializing...</div>;
  if (isInitialized) {
    content = (
      <>
        <Router>
          <Switch>
            <Route path="/signup/:linkKey" component={AccountCreationForm} />
            <ProtectedRoute path="/dashboard/:view/:id" component={Dashboard} />
            <ProtectedRoute path="/dashboard/:view" component={Dashboard} />
            <ProtectedRoute exact path="/dashboard/" component={Dashboard} />
            {/*<ProtectedRoute path='/epochs/enterprises/:enterpriseYearlyId' component={EnterpriseYearlyInfo} />*/}
            <ProtectedRoute
              exact
              path="/profile"
              component={UserDashboardWrapped}
            />
            {/* <ProtectedRoute exact path='/profile/choose' component={ChooseOptions}/> */}
            <Route exact path="/" component={Vitrine} />
            <Route
              exact
              path="/grilleEntreprises"
              component={EnterprisesGrid}
            />
            <Route exact path="/grilleEntreprises/:id" component={PublicPage} />
            <Route exact path="/login" component={UserLoginForm} />
            <ProtectedRoute exact path="/logout" component={UserLogoutForm} />
            <ProtectedRoute
              exact
              path="/epochs/links/add"
              component={AddLinkForm}
            />
            <ProtectedRoute
              path="/epochs/enterprises/:enterpriseId"
              component={EnterpriseYearlySinglePage}
            />
            <ProtectedRoute exact path="/epochs" component={EpochDashboard} />
            <ProtectedRoute
              exact
              path="/enterprises/add"
              component={AddEnterpriseForm}
            />

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
        <Footer />
      </>
    );
  }
  return content;
}

export default App;
