import React from "react";
import "./Ecoles.css";
import logoPonts from "../../media/logos/logos_ecoles/Logo_Ponts.png";
import { useFetchSchools } from "./SchoolsSlice";

export const Ecoles = () => {
  const fetchWrapper = useFetchSchools();

  const schools = fetchWrapper?.data;

  return (
    <div id="ecoles" className="ecoles-container-all">
      <div className="titre-ecoles-container">
        <div className="titre-ecoles">Écoles organisatrices</div>
        <div className="under-titre-ecoles" />
      </div>
      <div className="container-logos-ecoles">
        {schools.map((school) => {
          return (
            <a href={school?.website || ""} target="_blank" rel="noreferrer">
              <div className="logo-ecole">
                <img src={school?.logo || logoPonts} alt="logo Ponts" />
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
