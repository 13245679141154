import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  enterprisesYearlySelector,
  fetchEnterpriseYearlyById,
} from "./enterprisesYearlySlice";
import { Button } from "react-bootstrap";

interface MatchParams {
  enterpriseId: string | undefined;
}

interface EnterpriseYearlySinglePageProps
  extends RouteComponentProps<MatchParams> {}

export const EnterpriseYearlySinglePage = (
  props: EnterpriseYearlySinglePageProps
) => {
  const enterpriseId = parseInt(
    props.match.params.enterpriseId ? props.match.params.enterpriseId : "-1"
  );

  const dispatch = useDispatch();
  const enterpriseYearly = useSelector((state) =>
    enterprisesYearlySelector.selectById(state, enterpriseId)
  );
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (!enterpriseYearly) {
      // @ts-ignore
      dispatch(fetchEnterpriseYearlyById(enterpriseId));
    }
  }, [enterpriseYearly, dispatch, enterpriseId]);

  const onSaveClicked = () => {
    setEditMode(false);
  };

  const onEditClicked = () => {
    setEditMode(true);
  };

  let content = [];
  if (enterpriseYearly) {
    if (editMode) {
      content.push(<Button onClick={onSaveClicked}>Save</Button>);
    } else {
      content.push(<Button onClick={onEditClicked}>Edit</Button>);
    }
    content.push(
      <div>
        <div>{enterpriseYearly.name}</div>
        <div>{enterpriseYearly.workforce}</div>
        <div>{enterpriseYearly.turnover}</div>
        <div>{enterpriseYearly.enterprise.address}</div>
        <div>{enterpriseYearly.enterprise.year}</div>
        <div>{enterpriseYearly.enterprise.zip_code}</div>
        <div>{enterpriseYearly.enterprise.city}</div>
        <div>{enterpriseYearly.enterprise.phone_number}</div>
      </div>
    );
  }

  return content;
};
