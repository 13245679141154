import { FC } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/users/usersSlice";
import { AdminDashboard } from "./AdminDashboard/AdminDashboard";
import { RouteComponentProps } from "react-router";
import { useHistory } from "react-router-dom";

export const Dashboard: FC<
  RouteComponentProps<{ view?: string; id?: string }>
> = (props) => {
  const user = useSelector(selectCurrentUser);
  const history = useHistory();

  if (user?.staff) {
    return (
      <AdminDashboard
        view={props.match.params.view}
        id={props.match.params.id}
      />
    );
  }
  if (user?.active) {
    history.push("/profile");
    return null;
  } else {
    history.push("/");
    return null;
  }
};
