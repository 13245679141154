import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { User } from "./types";
import { STATUS } from "../../constants/rest";
import { AxiosError, AxiosResponse } from "axios";
import { API } from "../../api/rest";
import { useFetchDataNoRedux } from "../../api/hooks";
import { nullifyCurrentUser } from "../api/apiSlice";

const usersAdapter = createEntityAdapter<User>();

type State = {
  fetchStatus: string;
  currentUserId?: number;
} & EntityState<User>;

const initialState = usersAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
}) as State;

export const fetchCurrentUser = createAsyncThunk<
  User,
  undefined,
  { rejectValue: AxiosError }
>("user/fetchCurrentUser", (_, thunkAPI) => {
  return API.fetchCurrentUser()
    .then((response: AxiosResponse<User>) => response.data)
    .catch((error: AxiosError) => {
      thunkAPI.dispatch(nullifyCurrentUser());
      API.nullifyToken();
      return error;
    });
});

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    upsertOne: usersAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentUser.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetchCurrentUser.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      usersAdapter.upsertOne(state, action.payload);
      state.currentUserId = action.payload.id;
    });
  },
});

export default usersSlice.reducer;
export const usersSelector = usersAdapter.getSelectors(
  (state: any) => state.user
);
export const selectCurrentUser = (state: any) =>
  usersSelector.selectById(state, state.user.currentUserId);
export const usersActions = usersSlice.actions;

export const useFetchUser = (userId: number) =>
  useFetchDataNoRedux(
    (state: any) => usersSelector.selectById(state, userId),
    () => API.fetchUserById(userId),
    usersActions.upsertOne
  );
