import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

type CurrencyTextFieldProps = {
  onCurrencyChange: (value: string) => void;
  onValueChange: (value: number) => void;
  selectedCurrency: string;
  currencies: string[];
  value: number;
  label: string;
  helperText?: string;
  formControlProps?: React.ComponentProps<typeof FormControl>;
  inputLabelProps?: React.ComponentProps<typeof InputLabel>;
  inputProps?: React.ComponentProps<typeof OutlinedInput>;
  formHelperTextProps?: React.ComponentProps<typeof FormHelperText>;
};

function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const CurrencyTextField = ({
  currencies,
  onCurrencyChange,
  selectedCurrency,
  onValueChange,
  value,
  label,
  formControlProps,
  inputLabelProps,
  inputProps,
  formHelperTextProps,
  helperText,
}: CurrencyTextFieldProps): JSX.Element => {
  const displayValue = useMemo(() => numberWithCommas(value), [value]);
  const setValueFromDisplayValue = useCallback(
    (displayedValue: string) => {
      const trimValueString = displayedValue.replace(/,/g, "");
      const possibleValueNumber = parseInt(trimValueString);
      const valueNumber = isNaN(possibleValueNumber) ? 0 : possibleValueNumber;
      onValueChange(valueNumber);
    },
    [onValueChange]
  );

  return (
    <FormControl {...formControlProps}>
      <InputLabel htmlFor="currency-field" {...inputLabelProps}>
        {label}
      </InputLabel>
      <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>
      <OutlinedInput
        id="currency-field"
        {...inputProps}
        label={label}
        value={displayValue}
        onChange={(event) => setValueFromDisplayValue(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <FormControl disabled={formControlProps?.disabled}>
              <Select
                onChange={(event) =>
                  onCurrencyChange(event.target.value as string)
                }
                value={selectedCurrency}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </InputAdornment>
        }
        labelWidth={60}
      />
    </FormControl>
  );
};
