import React, { FC, useContext, useEffect } from "react";
import { Epoch, useFetchEpoch } from "../epochs/epochsSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../constants/rest";
import { contentFromStatus } from "../../helpers/displayHelpers";
import {
  fetchLinks,
  selectLinksFetchStatus,
  selectLinksFromEpoch,
} from "./linksSlice";
import DataGrid from "react-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { EpochContext } from "../../components/AdminDashboard/AdminDashboard";
import { Button, Paper } from "@material-ui/core";
import { getLinkFromKey } from "../../helpers/utils";
import { useSnackbar } from "notistack";

type LinksListProps = {
  epoch: Epoch;
};

export const LinksList: FC = () => {
  const dispatch = useDispatch();
  const { selectedEpochId } = useContext(EpochContext);
  const fetchWrapper = useFetchEpoch(selectedEpochId);
  const epoch = fetchWrapper.data;
  const fetchStatus = useSelector(selectLinksFetchStatus);
  const links = useSelector(selectLinksFromEpoch(epoch));
  const { enqueueSnackbar } = useSnackbar();

  const columns = [
    {
      key: "enterprise",
      name: "enterprise",
      formatter: (linkRow: any) => {
        return <span>{linkRow.row.enterprise.name}</span>;
      },
    },
    {
      key: "key",
      name: "key",
      formatter: (linkRow: any) => {
        return (
          <Button
            style={{
              margin: "auto",
              alignSelf: "center",
              justifySelf: "center",
              width: "100%",
            }}
            onClick={() => {
              navigator.clipboard
                .writeText(getLinkFromKey(linkRow.row.key))
                .then(() => {
                  enqueueSnackbar("Lien copié dans le presse-papier.", {
                    variant: "success",
                  });
                });
            }}
          >
            {linkRow.row.key} (copy)
          </Button>
        );
      },
    },
    {
      key: "is_used",
      name: "status",
      formatter: (linkRow: any) => {
        if (linkRow.row.is_used) {
          return (
            <FontAwesomeIcon
              icon={faTimes}
              width={"100%"}
              style={{ margin: "auto", width: "100%", color: "red" }}
            />
          );
        } else {
          return (
            <FontAwesomeIcon
              icon={faCheck}
              width={"100%"}
              style={{ margin: "auto", width: "100%", color: "green" }}
            />
          );
        }
      },
    },
  ];

  useEffect(() => {
    if (fetchStatus === STATUS.IDLE) {
      dispatch(fetchLinks());
    }
  });

  let content = contentFromStatus(
    fetchStatus,
    () => {
      if (epoch) {
        return (
          <Paper elevation={10} style={{ marginTop: "10px" }}>
            <DataGrid columns={columns} rows={links} />
          </Paper>
        );
      } else {
        return null;
      }
    },
    () => {
      return <div>Error.</div>;
    }
  );

  if (content) {
    return content as JSX.Element;
  } else {
    return <div>You should never land here</div>;
  }
};
