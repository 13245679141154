import React, { FC, ReactNode } from "react";
import { contentFromStatus } from "../../helpers/displayHelpers";

export const StatusGuard: FC<{
  status: string;
  data?: any;
  children?: ReactNode;
}> = (props) => {
  return contentFromStatus(props.status, () => {
    return <>{props.children}</>;
  });
};
