import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
} from "@reduxjs/toolkit";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { AxiosError } from "axios";
import { useFetchData, useFetchDataNoRedux } from "../../api/hooks";

type Epoch = {
  id: number;
  name: string;
  is_active: boolean;
  enterprises: number[];
};
export type { Epoch };

type State = {
  fetchStatus: string;
  fetchError: undefined | null | AxiosError<any> | unknown;
  activeEpochId: undefined | null | EntityId;
} & EntityState<Epoch>;

const epochsAdapter = createEntityAdapter<Epoch>({
  sortComparer: (a, b) => -1 * a.name.localeCompare(b.name),
});

const initialState = epochsAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  fetchError: null,
  activeEpochId: null,
}) as State;

const fetch = createAsyncThunk("epochs/fetch", async (_, thunkAPI) => {
  return API.fetchEpochs()
    .then((response: any) => response.data)
    .catch((error: any) =>
      thunkAPI.rejectWithValue({ code: error.code, message: error.message })
    );
});
export { fetch as fetchEpochs };

// Fetch one single Epoch
const fetchById = createAsyncThunk(
  "epochs/fetchById",
  async (id: number, thunkAPI) => {
    return API.fetchEpochById(id)
      .then((response: any) => response.data)
      .catch((error: any) =>
        thunkAPI.rejectWithValue({ code: error.code, message: error.message })
      );
  }
);
export { fetchById as fetchEpochById };

const epochsSlice = createSlice({
  name: "epochs",
  initialState,
  reducers: {
    selectActiveEpoch: (state, action) => {
      state.activeEpochId = action.payload;
    },
    addOne: epochsAdapter.addOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      epochsAdapter.setAll(state, action.payload);
      if (state.ids.length > 0) {
        state.activeEpochId = state.ids[0];
      }
      state.fetchStatus = STATUS.FULFILLED;
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchError = action.payload;
      state.fetchStatus = STATUS.FAILED;
    });
    builder.addCase(fetchById.fulfilled, epochsAdapter.upsertOne);
  },
});
export const { selectActiveEpoch } = epochsSlice.actions;
export const epochsSelector = epochsAdapter.getSelectors(
  (state: any) => state.epochs
);
export const selectSelectedEpoch = (state: any) =>
  state.epochs.entities[state.epochs.activeEpochId];
export const epochsActions = epochsSlice.actions;
export const selectEpochsFetchStatus = (state: any) => state.epochs.fetchStatus;
export const selectEpochsFetchError = (state: any) => state.epochs.fetchError;

export default epochsSlice.reducer;

// Hooks
export const useFetchEpochs = () =>
  useFetchData<Epoch[]>(
    epochsSelector.selectAll,
    selectEpochsFetchStatus,
    selectEpochsFetchError,
    fetch(),
    true
  );
export const useFetchEpoch = (epochId: number) =>
  useFetchDataNoRedux<Epoch>(
    (state: any) => epochsSelector.selectById(state, epochId),
    () => API.fetchEpochById(epochId),
    epochsActions.addOne
  );
