import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { Enterprise } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { STATUS } from "../../constants/rest";
import { useFetchData, useFetchDataNoRedux } from "../../api/hooks";

const enterprisesAdapter = createEntityAdapter<Enterprise>();

type EnterprisesSliceState = {
  fetchStatus: string;
  error: null | AxiosError;
} & EntityState<Enterprise>;

const initialState = enterprisesAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
}) as EnterprisesSliceState;

const fetch = createAsyncThunk<Enterprise[], number | undefined>(
  "enterprises/fetch",
  async (epochId, thunkAPI) => {
    return API.fetchEnterprises(epochId)
      .then((response: AxiosResponse<Enterprise[]>) => response.data)
      .catch((error: AxiosError<Enterprise[]>) =>
        thunkAPI.rejectWithValue(error)
      );
  }
);
export { fetch as fetchEnterprises };

const fetchById = createAsyncThunk<Enterprise, number>(
  "enterprises/fetchById",
  async (id, thunkAPI) => {
    return API.fetchEnterpriseById(id)
      .then((response: AxiosResponse<Enterprise>) => response.data)
      .catch((error: AxiosError<Enterprise>) => error);
  }
);
export { fetchById as fetchEnterpriseById };

const enterprisesSlice = createSlice({
  name: "enterprises",
  initialState: initialState,
  reducers: {
    add: enterprisesAdapter.addOne,
    upsertMany: enterprisesAdapter.upsertMany,
    upsertOne: enterprisesAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      enterprisesAdapter.setAll(state, action.payload);
      state.fetchStatus = STATUS.FULFILLED;
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload as AxiosError;
    });
  },
});
export const enterprisesActions = enterprisesSlice.actions;

const selectCanCreateLink = (epochId: number) => (state: any) => {
  if (!epochId) {
    return [];
  }
  return enterprisesSelector.selectAll(state).filter((enterprise) => {
    return !enterprise.epochs.includes(epochId);
  });
};
export { selectCanCreateLink as selectEnterprisesCanCreateLink };
export default enterprisesSlice.reducer;

// Selectors

export const enterprisesSelector = enterprisesAdapter.getSelectors(
  (state: any) => state.enterprises
);
export const selectEnterprisesFetchStatus = (state: any) =>
  state.enterprises.fetchStatus;
export const selectEnterprisesFetchError = (state: any) =>
  state.enterprises.error;

// Hooks

export const useFetchEnterprises = (epochId?: number) =>
  useFetchData<Enterprise[]>(
    enterprisesSelector.selectAll,
    selectEnterprisesFetchStatus,
    selectEnterprisesFetchError,
    fetch(epochId),
    true
  );
export const useFetchEnterprise = (id: EntityId) =>
  useFetchDataNoRedux<Enterprise>(
    (state: any) => enterprisesSelector.selectById(state, id),
    () => API.fetchEnterpriseById(id as number),
    enterprisesActions.upsertOne
  );
