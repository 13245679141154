import React from "react";
import { Link } from "react-router-dom";

import "./EntreprisesTypesTab.css";

import logoTrium from "../../media/logos/logo_trium.png";
import defense from "../../media/defense.jpg";

import { useFetchTypes } from "../enterprises/enterpriseTypesSlice";

export const EntreprisesTypesTab = () => {
  const fetchWrapperTypes = useFetchTypes();
  const types = fetchWrapperTypes.data;

  return (
    <div>
      <div className="type-grid-container">
        {types.map((type) => (
          <div
            key={type.id}
            className="grid-item-bg"
            style={{ backgroundImage: `url(${type?.picture || logoTrium})` }}
          >
            <Link
              to={{
                pathname: "/grilleEntreprises",
                state: { type: type.id },
              }}
              style={{ textDecoration: "none" }}
            >
              <div className="grid-item-txt">{type.name}</div>
            </Link>
          </div>
        ))}
      </div>
      <div className="all-types-box">
        <div
          className="all-types-bg"
          style={{
            backgroundImage: `linear-gradient(rgba(6, 5, 41, 0.45), rgba(6, 5, 41, 0.45)), url(${defense})`,
          }}
        >
          <Link
            to={{
              pathname: "/grilleEntreprises",
              state: { type: 1000 },
            }}
            style={{ textDecoration: "none" }}
          >
            <div className="all-types-txt">Voir toutes les entreprises</div>
          </Link>
        </div>
      </div>
    </div>
  );
};
