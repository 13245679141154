import React, { useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";

import logoTrium from "../../media/logos/logo_trium.png";

import "./EnterprisesGrid.css";

import { useFetchPublicEnterprises } from "./enterprisesPublicSlice";
import { useFetchTypes } from "../enterprises/enterpriseTypesSlice";

//import {contentFromStatus} from "../../helpers/displayHelpers";
import PropTypes from "prop-types";
//import { makeStyles } from '@material-ui/core/styles';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';
import ScrollToTopOnMount from "../../helpers/ScrollTop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="zone-grid-tab-entreprises"
      {...other}
    >
      {value === index && (
        <>
          <div className="entreprises-grid-container">{children}</div>
        </>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export const EnterprisesGrid = (props) => {
  const fetchWrapperPublic = useFetchPublicEnterprises();
  const fetchWrapperTypes = useFetchTypes();
  const entreprises = fetchWrapperPublic?.data;
  const types = fetchWrapperTypes?.data;

  let history = useHistory();
  const location = useLocation();

  const typeInit = location?.state?.type;

  const [value, setValue] = useState(typeInit || 1000);

  function handleClickAcc() {
    history.push(`/`);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [orientation, setOrientation] = useState(
    window.innerWidth > 600 ? "vertical" : "horizontal"
  );

  React.useEffect(() => {
    function handleResize() {
      setOrientation(window.innerWidth > 600 ? "vertical" : "horizontal");
    }

    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <ScrollToTopOnMount />
      <div className="public-grid-container-all">
        <Button variant="outlined" onClick={handleClickAcc}>
          Retour à l'accueil
        </Button>
        <div className="titre-grid-entreprises-container">
          <div className="titre-grid-entreprises">Les entreprises du Forum</div>
          <div className="under-titre-grid-entreprises" />
        </div>

        <div className="root-tabs-grid-entreprises">
          <Tabs
            orientation={orientation}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            scrollButtons="on"
            aria-label="Vertical tabs example"
            className="tabs-grid-entreprises"
          >
            <Tab
              label="Voir toutes"
              id={"vertical-tab-1000"}
              aria-controls={"vertical-tabpanel-1000"}
              value={1000}
            />

            {types.map((type) => (
              <Tab
                label={type.name}
                id={`vertical-tab-${type?.id}`}
                aria-controls={`vertical-tabpanel-${type?.id}`}
                value={type?.id}
              />
            ))}
          </Tabs>

          <TabPanel value={value} index={1000}>
            {entreprises
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map((ent) => (
                    <div className="card-entreprise">
                        <Link
                        to={`${props.match.url}${ent?.id ? "/" + ent.id : ""}`}
                        style={{ textDecoration: "none" }}
                        >
                        <div
                            className="card-logo"
                            style={{
                            backgroundImage: `url(${ent?.logo || logoTrium})`,
                            }}
                        />
                        <div className="card-name">{ent.name}</div>
                        </Link>
                    </div>
                ))
            }
          </TabPanel>

          {types.map((type) => (
            <TabPanel value={value} index={type.id}>
              {entreprises
                .filter((en) => en?.enterprise_type === type.id)
                .sort((a, b) => a.name < b.name ? -1 : 1)
                .map((ent) => (
                  <div className="card-entreprise">
                    <Link
                      to={`${props.match.url}${ent?.id ? "/" + ent.id : ""}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="card-logo"
                        style={{
                          backgroundImage: `url(${ent?.logo || logoTrium})`,
                        }}
                      />
                      <div className="card-name">{ent.name}</div>
                    </Link>
                  </div>
                ))}
            </TabPanel>
          ))}
        </div>
      </div>
    </>
  );
};
