import React, { FC, useMemo } from "react";
import { useFetchEnterprises } from "./enterprisesSlice";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { makeStyles } from "@material-ui/core";
import DataGrid, { Column } from "react-data-grid";
import { Enterprise } from "./types";
import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));
export const EnterprisesList: FC = () => {
  const { fetchStatus, data } = useFetchEnterprises();
  const enterprisesList = useMemo(() => data?.sort((a, b) => a.name < b.name ? -1 : 1), [data]);
  const classes = useStyle();

  const columns = [
    {
      name: "Nom",
      key: "name",
      formatter: (value) => {
        if (value.row)
          return (
            <Link to={`/dashboard/enterprises/${value.row.id}`}>
              {value.row.name}
            </Link>
          );
        return null;
      },
    },
  ] as Column<Enterprise>[];

  return contentFromStatus(fetchStatus, () => {
    return (
      <div className={classes.root}>
        <DataGrid columns={columns} rows={enterprisesList ?? []} />
      </div>
    );
  });
};
