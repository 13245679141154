import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchData } from "../../api/hooks";

const schoolsAdapter = createEntityAdapter();

const initialState = schoolsAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetch = createAsyncThunk("users/schools/fetch", async (_, thunkAPI) => {
  return API.fetchSchools()
    .then((response) => response.data)
    .catch((error) => thunkAPI.rejectWithValue(error));
});
export { fetch as fetchSchools };

const schoolsSlice = createSlice({
  name: "schools",
  initialState: initialState,
  reducers: {
    upsertOne: schoolsAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      schoolsAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const schoolsActions = schoolsSlice.actions;

const schoolsSelector = schoolsAdapter.getSelectors((state) => state.schools);
export const { selectAll: selectAllSchools } = schoolsSelector;

const selectFetchStatus = (state) => state.schools.fetchStatus;
export { selectFetchStatus as selectSchoolsFetchStatus };

const selectFetchError = (state) => state.schools.fetchError;
export { selectFetchError as selectSchoolsFetchError };

export default schoolsSlice.reducer;

export const useFetchSchools = () =>
  useFetchData(
    schoolsSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
