import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import store, {persistor} from "./app/store";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/lib/integration/react";

import "bootstrap/dist/css/bootstrap.min.css";
import {SnackbarProvider} from "notistack";
import {Slide} from "@material-ui/core";

// We can fetch data here.
// store.dispatch(fetchEnterprises())

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    TransitionComponent={Slide}
                    autoHideDuration={3000}
                >
                    <App/>
                </SnackbarProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
