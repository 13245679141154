import React, { useEffect, useState } from "react";
import { STATUS } from "../../constants/rest";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { RouteComponentProps } from "react-router";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { emailRegex } from "../../helpers/utils";

interface MatchParams {
  linkKey: string;
}

type AccountCreationFormProps = RouteComponentProps<MatchParams>;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUp: React.FC<{ onClick: any; errors: any }> = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [job, setJob] = useState("");
  const [phone_number, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setCheckPassword] = useState("");

  const history = useHistory();

  return (
    <Container component="main" maxWidth="xs" style={{ minHeight: "80vh" }}>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Création de compte
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Prénom"
                autoFocus
                value={first_name}
                onChange={(event) => setFirstName(event.target.value)}
                error={props.errors.first_name}
                helperText={props.errors.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Nom"
                name="lastName"
                autoComplete="lname"
                value={last_name}
                onChange={(event) => setLastName(event.target.value)}
                error={props.errors.last_name}
                helperText={props.errors.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={props.errors.email}
                helperText={props.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="job"
                label="Profession"
                name="job"
                autoComplete="job"
                value={job}
                onChange={(event) => setJob(event.target.value)}
                error={props.errors.job}
                helperText={props.errors.job}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone"
                label="Téléphone"
                name="Phone"
                autoComplete="phone"
                value={phone_number}
                onChange={(event) => setPhone(event.target.value)}
                error={props.errors.phone_number}
                helperText={props.errors.phone_number}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                error={props.errors.password}
                helperText={props.errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password2"
                label="Mot de passe (Confirmation)"
                type="password"
                id="password2"
                autoComplete="current-password"
                value={password2}
                onChange={(event) => setCheckPassword(event.target.value)}
                error={props.errors.password2}
                helperText={props.errors.password2}
              />
            </Grid>
          </Grid>
          <Button
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() =>
              props.onClick({
                email,
                first_name,
                last_name,
                phone_number,
                password,
                password2,
                job,
              })
            }
          >
            Créer mon compte
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  history.push("/login");
                }}
              >
                Vous êtes déjà inscrit ? Se connecter
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

type formArgs = {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  password: string;
  password2: string;
  job: string;
  linkKey: string;
};

export const AccountCreationForm = (props: AccountCreationFormProps) => {
  const linkKey = props.match.params.linkKey;
  const [checkStatus, setCheckStatus] = useState(STATUS.IDLE);
  const [checkError, setCheckError] = useState("");
  const [createAccountStatus, setCreateAccountStatus] = useState(STATUS.IDLE);
  const [createAccountError, setCreateAccountError] = useState<formArgs>({
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    password: "",
    password2: "",
    job: "",
    linkKey: "",
  });

  useEffect(() => {
    if (checkStatus === STATUS.IDLE) {
      setCheckStatus(STATUS.PENDING);
      API.checkLink(linkKey)
        .then((response: AxiosResponse<any>) => {
          setCheckStatus(STATUS.FULFILLED);
        })
        .catch((error: AxiosError<any>) => {
          setCheckStatus(STATUS.FAILED);
          setCheckError(error.message);
        });
    }
  }, []);

  return contentFromStatus(
    checkStatus,
    () => {
      if (createAccountStatus === STATUS.FULFILLED) {
        return <Redirect to="/login" />;
      }
      return (
        <SignUp
          errors={createAccountError}
          onClick={(account: formArgs) => {
            let hasError = false;
            let errors = {
              email: "",
              first_name: "",
              last_name: "",
              phone_number: "",
              password: "",
              password2: "",
              job: "",
              linkKey: "",
            };

            // C'EST HONTEUX
            if (!account.first_name) {
              hasError = true;
              errors.first_name = "Missing field";
            }

            if (!account.last_name) {
              hasError = true;
              errors.last_name = "Missing field";
            }

            if (!emailRegex.test(account.email)) {
              hasError = true;
              errors.email = "Invalid Email";
            }

            if (!account.email) {
              hasError = true;
              errors.email = "Missing field";
            }

            if (!account.job) {
              hasError = true;
              errors.job = "Missing field";
            }

            if (!account.phone_number) {
              hasError = true;
              errors.phone_number = "Missing field";
            }

            if (!account.password) {
              hasError = true;
              errors.password = "Missing field";
            }

            if (account.password !== account.password2) {
              hasError = true;
              errors.password2 = "Passwords mismatch";
            }

            setCreateAccountError(errors);

            if (hasError) {
              return;
            }
            if (createAccountStatus !== STATUS.PENDING) {
              account.linkKey = linkKey;
              const requestParams = {
                first_name: account.first_name,
                last_name: account.last_name,
                password: account.password,
                linkKey: account.linkKey,
                email: account.email,
                phone_number: account.phone_number,
                job: account.job,
              };
              API.createAccount(requestParams)
                .then(() => {
                  setCreateAccountStatus(STATUS.FULFILLED);
                })
                .catch((error: AxiosError) => {
                  setCreateAccountStatus(STATUS.FAILED);
                  console.log(error.response);
                  setCreateAccountError({
                    ...errors,
                    ...error.response?.data,
                  });
                });
            }
          }}
        />
      );
    },
    () => {
      // TODO: detail error from error code
      return <div>Invalid Link: {checkError}</div>;
    }
  );
};
