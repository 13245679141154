import React, { FC, useContext, useState } from "react";
import { Epoch, useFetchEpoch } from "../epochs/epochsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  enterprisesActions,
  selectEnterprisesCanCreateLink,
} from "../enterprises/enterprisesSlice";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { Enterprise } from "../enterprises/types";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { Button, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { linksActions } from "./linksSlice";
import { EpochContext } from "../../components/AdminDashboard/AdminDashboard";
import { useSnackbar } from "notistack";
import { getLinkFromKey } from "../../helpers/utils";

type CreateLinkFormProps = {
  epoch?: Epoch;
};
type EnterpriseSelectorProps = {
  enterprises?: Enterprise[];
  onChange: (enterprise: Enterprise) => void;
};

const EnterpriseSelector = (props: EnterpriseSelectorProps) => {
  const [selectedEnterprise, setSelectedEnterprise] =
    useState<Enterprise | null>(null);
  const enterprises = props.enterprises;

  return (
    <Autocomplete
      options={enterprises as Enterprise[]}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Choisir une entreprise"
            variant="outlined"
            inputProps={{ ...params.inputProps, autoComplete: "new-password" }}
          />
        );
      }}
      getOptionLabel={(enterprise) => enterprise.name}
      autoComplete
      autoHighlight
      autoSelect
      blurOnSelect
      onChange={(_, value) => props.onChange(value as Enterprise)}
    />
  );
};

export const CreateLinkForm: FC = () => {
  const { selectedEpochId } = useContext(EpochContext);
  const fetchWrapper = useFetchEpoch(selectedEpochId);
  const epoch = fetchWrapper.data;

  const dispatch = useDispatch();
  const canCreateLinkEnterprises = useSelector(
    selectEnterprisesCanCreateLink(epoch?.id ?? -1)
  );
  const [selectedEnterprise, setSelectedEnterprise] =
    useState<null | Enterprise>(null);
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);
  const [createLinkStatus, setCreateLinkStatus] = useState(STATUS.IDLE);
  const [createError, setCreateError] = useState<null | AxiosError>(null);
  const { enqueueSnackbar } = useSnackbar();

  if (!epoch) {
    return null;
  }

  if (fetchStatus === STATUS.IDLE) {
    // @ts-ignore
    setFetchStatus(STATUS.PENDING);
    API.fetchEnterprises(epoch.id)
      .then((response: AxiosResponse<Enterprise[]>) => {
        dispatch(enterprisesActions.upsertMany(response.data));
        setFetchStatus(STATUS.FULFILLED);
      })
      .catch((error: AxiosError) => {
        setFetchStatus(STATUS.FAILED);
      });
  }

  const handleCreateLinkClicked = () => {
    const enterprise = selectedEnterprise;
    if (enterprise && createLinkStatus !== STATUS.PENDING) {
      API.createLink({
        enterprise: enterprise.id,
        validity_time: 5,
        epoch: epoch.id,
      })
        .then((response: any) => {
          setCreateLinkStatus(STATUS.FULFILLED);
          dispatch(linksActions.addOne(response.data));
          navigator.clipboard
            .writeText(getLinkFromKey(response.data.key))
            .then(() => {
              enqueueSnackbar(
                "Lien créé avec succès et copié dans le presse-papier. ",
                { variant: "success" }
              );
            });
        })
        .catch((error: AxiosError) => {
          setCreateError(error);
          enqueueSnackbar(error.message, { variant: "error" });
        });
    }
  };

  let content = contentFromStatus(fetchStatus, () => {
    return (
      <Paper elevation={3}>
        <EnterpriseSelector
          enterprises={canCreateLinkEnterprises}
          onChange={setSelectedEnterprise}
        />
        <Button onClick={handleCreateLinkClicked}>Générer un lien</Button>
      </Paper>
    );
  }) as JSX.Element;
  if (content === undefined) {
    return null;
  } else {
    return content;
  }
};
