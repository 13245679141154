// Use Redux to see selected epoch then fetch and display data accordingly
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedEpoch } from "../epochs/epochsSlice";
import {
  enterprisesYearlySelector,
  fetchEnterpriseYearlyById,
} from "./enterprisesYearlySlice";
import DataGrid from "react-data-grid";
import { Link } from "react-router-dom";
import { EnterpriseYearly } from "./types";

export const EnterprisesYearlyList = () => {
  const selectedEpoch = useSelector(selectSelectedEpoch);
  const dispatch = useDispatch();
  const enterprisesYearly = useSelector(
    enterprisesYearlySelector.selectEntities
  );

  const fetchHelper = (ids: Array<number>) => {
    ids.forEach((id: number) => {
      // @ts-ignore
      dispatch(fetchEnterpriseYearlyById(id));
    });
  };

  useEffect(() => {
    const toFetchIds = selectedEpoch.enterprises.filter(
      (enterpriseId: number) => {
        return !enterprisesYearly[enterpriseId];
      }
    );
    fetchHelper(toFetchIds);
  }, [selectedEpoch]);

  let content = null;
  if (selectedEpoch) {
    const relevantEnterprisesYearly = selectedEpoch.enterprises
    .map(
      (enterpriseId: number) => {
        return enterprisesYearly[enterpriseId];
      }
    )
    .sort((a: EnterpriseYearly, b: EnterpriseYearly) => a.name < b.name ? -1 : 1);

    const NameFormatter = (value: any) => {
      if (value.row) {
        return (
          <Link to={`/epochs/enterprises/${value.row.id}`}>
            {value.row.name}
          </Link>
        );
      } else {
        return null;
      }
    };

    const columns = [
      { key: "id", name: "ID" },
      {
        key: "name",
        name: "Title",
        sortable: true,
        sortDescendingFirst: true,
        formatter: NameFormatter,
      },
    ];

    content = (
      <div>
        <DataGrid columns={columns} rows={relevantEnterprisesYearly} />
      </div>
    );
  }
  return content;
};
