import React from "react";
import { Parallax } from "react-parallax";
import imgChantier from "../../media/photos_accueil/chantier.jpg";

import Button from "@material-ui/core/Button";

import { useFetchInfosGlobales } from "./InfosGlobalesSlice";

import "./Forum.css";

export const Forum = () => {
  const fetchWrapperInfos = useFetchInfosGlobales();
  const infosGlobales = fetchWrapperInfos?.data[0];

  // let history = useHistory();

  // function handleClickAtrium() {
  //     history.push(`/grilleEntreprises`);
  // }

  return (
    <div id="forum">
      <Parallax bgImage={imgChantier} bgImageAlt="grue" strength={300}>
        <div className="forum-container">
          <div className="forum-text-container forum-titre-container">
            <div className="titre-forum">Le Forum</div>
            <div className="under-titre-forum" />
          </div>
          <div className="forum-chiffres-container">
            <div className="chiffres-et-legende">
              <p className="chiffre-forum">1991</p>
              <p className="legende-chiffre-forum">
                Année de
                <br />
                création
              </p>
            </div>
            <div className="separation-chiffres" />
            <div className="chiffres-et-legende">
              <p className="chiffre-forum">
                {infosGlobales?.nb_entreprises || "207"}
              </p>
              <p className="legende-chiffre-forum">Entreprises</p>
            </div>
            <div className="separation-chiffres" />
            <div className="chiffres-et-legende">
              <p className="chiffre-forum">
                {infosGlobales?.nb_visiteurs || "3000"}
              </p>
              <p className="legende-chiffre-forum">Visiteurs</p>
            </div>
            <div className="separation-chiffres" />
            <div className="chiffres-et-legende">
              <p className="chiffre-forum">4</p>
              <p className="legende-chiffre-forum">Écoles</p>
            </div>
          </div>
          <div
            className="forum-text-container text-descr-forum"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {infosGlobales?.txt_presentation}
          </div>
          <div className="forum-text-container flex-button-atrium-container">
            <a
              href={infosGlobales?.atrium || "/"}
              target="_blank"
              rel="noopener noreferrer"
              className="button-atrium"
            >
              <Button variant="contained" size="large" color="primary">
                Se préparer pour le Forum
              </Button>
            </a>
            <a
              href={infosGlobales?.deroulement || "/"}
              target="_blank"
              rel="noopener noreferrer"
              className="button-atrium"
            >
              <Button variant="contained" size="large" color="primary">
                Le déroulé du Forum
              </Button>
            </a>
          </div>
        </div>
      </Parallax>
    </div>
  );
};
