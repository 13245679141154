import { createSlice } from "@reduxjs/toolkit";
import { nullifyCurrentUser, setAPIToken } from "../api/apiSlice";
import { API } from "../../api/rest";
import { fetchCurrentUser } from "../users/usersSlice";

const initialState = {
  isInitialized: false,
  error: null,
};

export const initialize = () => async (dispatch) => {
  // Where the magic happens
  try {
    dispatch(setAPIToken());
    if (API.hasToken()) {
      await dispatch(fetchCurrentUser());
    }

    API.fetchCurrentUser().catch((error) => {
      dispatch(nullifyCurrentUser());
    });

    dispatch(setReady());
  } catch (e) {
    console.log(e);
    // dispatch(setError(e));
  }
};

const initializerSlice = createSlice({
  name: "initializer",
  initialState: initialState,
  reducers: {
    setReady: (state) => {
      state.isInitialized = true;
    },
    setError: (state, action) => {
      state.isInitialized = false;
      state.error = action.payload;
    },
  },
});

export const { setReady, setError } = initializerSlice.actions;

// Selectors
export const selectIsInitialized = (state) => state.initializer.isInitialized;

export default initializerSlice.reducer;
