import React, { FC, useState } from "react";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LinkIcon from "@material-ui/icons/Link";
import HomeIcon from "@material-ui/icons/Home";
import { LogoutButton } from "../UserComponents/LogoutButton";
import { EpochSelector } from "../../features/epochs/EpochSelector";
import { useHistory } from "react-router-dom";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: "30px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  epochSelector: {
    marginRight: theme.spacing(2),
    marginLeft: "auto",
  },
  backButton: {
    width: "5rem",
  },
}));

export const Navbar: FC = () => {
  const classes = useStyles();
  const history = useHistory();

  // const {data, fetchStatus, fetchError} = useFetchEpochs()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  const NavbarDrawer: FC = () => {
    return (
      <Drawer
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <div onClick={toggleDrawer(false)}>
          <List>
            <ListItem
              button
              key="profile"
              onClick={() => {
                history.push("/dashboard/home");
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Mon Profil" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key="Links"
              onClick={() => {
                history.push("/dashboard/links");
              }}
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Liens de création de compte" />
            </ListItem>
            <ListItem
              button
              key="EnterprisesYearly"
              onClick={() => {
                history.push("/dashboard/enterprisesyearly");
              }}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Les entreprises de l'année" />
            </ListItem>
            <ListItem
              button
              key="MyEnterprisesYearly"
              onClick={() => {
                history.push("/dashboard/myenterprises");
              }}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary="Mes entreprises" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem
              button
              key="Enterprises"
              onClick={() => {
                history.push("/dashboard/enterprises");
              }}
            >
              <ListItemIcon>
                <BusinessCenterIcon />
              </ListItemIcon>
              <ListItemText primary={"Toutes les entreprises"} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" className={classes.root}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(!isDrawerOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Dashboard staff
          </Typography>
          <Button
            className={classes.backButton}
            variant={"contained"}
            onClick={() => {
              history.goBack();
            }}
          >
            Retour
          </Button>
          <EpochSelector className={classes.epochSelector} />
          <LogoutButton />
        </Toolbar>
      </AppBar>
      <NavbarDrawer />
    </React.Fragment>
  );
};
