import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { STATUS } from "../constants/rest";
import { AxiosError, AxiosPromise } from "axios";
import { AnyAction } from "@reduxjs/toolkit";

interface useFetchDataReturnType<Type> {
  data?: Type;
  fetchStatus: string;
  fetchError?: AxiosError<Type>;
  action: any;
}

export const useFetchData = <Type>(
  dataSelector: (state: any) => Type,
  fetchStatusSelector: (state: any) => string,
  fetchErrorSelector: (state: any) => AxiosError<Type>,
  action: any,
  ensure?: boolean
) => {
  const data = useSelector(dataSelector);
  const fetchStatus = useSelector(fetchStatusSelector);
  const fetchError = useSelector(fetchErrorSelector);
  const dispatch = useDispatch();

  const boundAction = useCallback(() => {
    return dispatch(action);
  }, [dispatch, action]);

  useEffect(() => {
    if (
      (ensure && fetchStatus === STATUS.IDLE) ||
      (!data && fetchStatus !== STATUS.PENDING)
    ) {
      boundAction();
    }
  }, [boundAction, data, fetchStatus, ensure]);

  return {
    data,
    fetchStatus,
    fetchError,
    action: boundAction,
  } as useFetchDataReturnType<Type>;
};

export const useFetchDataNoRedux = <Type>(
  dataSelector: (state: any) => Type | undefined,
  fetcher: () => AxiosPromise<Type>,
  setter: AnyAction,
  dataChecker?: (data?: Type) => boolean
) => {
  const data = useSelector(dataSelector);
  const dispatch = useDispatch();
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);
  const [fetchError, setFetchError] = useState({} as AxiosError<Type>);
  // const localDataChecker = dataChecker ?? ((data?: Type) => {return data != null})

  const boundAction = useCallback(() => {
    fetcher()
      .then((result) => {
        // @ts-ignore
        dispatch(setter(result.data));
        setFetchStatus(STATUS.FULFILLED);
      })
      .catch((error) => {
        setFetchError(error);
        setFetchStatus(STATUS.FAILED);
      });
  }, [fetcher, setter, dispatch]);

  useEffect(() => {
    if (!data && fetchStatus !== STATUS.PENDING) {
      boundAction();
    } else {
      setFetchStatus(STATUS.FULFILLED);
    }
  }, [boundAction, data, fetchStatus]);

  return {
    data,
    fetchStatus,
    fetchError,
  } as useFetchDataReturnType<Type>;
};
