// export const API_URL = "https://trium-admin.enpc.org/";
export const API_URL =
  process.env.REACT_APP_API_URL ?? "http://localhost:5050/";

export const FETCH_LINKS_URL = () => API_URL + "account_creation/links/";
export const VALIDATE_LINK = (linkKey) =>
  API_URL + `account_creation/?key=${linkKey}`;
export const CREATE_LINK_URL = () => API_URL + "account_creation/links/";
export const CREATE_ACCOUNT = () => API_URL + "account_creation/";
export const LOGIN_URL = () => API_URL + "token-auth/";
export const FETCH_CURRENT_USER_URL = () => API_URL + "users/current_user/";
export const FETCH_ENTERPRISES_PUBLIC_URL = () =>
  API_URL + "enterprises/public/";
export const FETCH_ENTERPRISES_URL = (epochId) =>
  !epochId
    ? API_URL + "enterprises/"
    : API_URL + `enterprises?epoch=${epochId}`;
export const FETCH_EPOCHS_URL = () => API_URL + "enterprises/epochs";
export const FETCH_EPOCH_BY_ID_URL = (id) =>
  API_URL + `enterprises/epochs/${id}`;
export const FETCH_ENTERPRISE_YEARLY_BY_ID_URL = (id) =>
  API_URL + `enterprises/yearly/${id}/`;
export const FETCH_ENTERPRISE_BY_ID_URL = (id) => API_URL + `enterprises/${id}`;
export const FETCH_ENTERPRISE_PUBLIC_BY_ID_URL = (id) =>
  API_URL + `enterprises/public/${id}`;
export const FETCH_LINK_BY_ID_URL = (id) =>
  API_URL + `account_creation/links/${id}`;
export const FETCH_ENTERPRISE_TYPES_URL = () => API_URL + "enterprises/types/";
export const ADD_ENTERPRISE_URL = () => API_URL + "enterprises/";
export const UPDATE_USER_URL = (userId) => API_URL + `users/${userId}/`;
export const UPDATE_ENTERPRISE_URL = (enterpriseId) =>
  API_URL + `enterprises/${enterpriseId}/`;
export const UPDATE_ENTERPRISE_YEARLY_URL = (enterpriseYearlyId) =>
  FETCH_ENTERPRISE_YEARLY_BY_ID_URL(enterpriseYearlyId);
export const FETCH_CATOPTIONS_URL = () =>
  API_URL + `options-commandes/catoptions/`;
export const FETCH_OPTIONS_URL = (epochId) =>
  API_URL + `options-commandes/options?epoch=${epochId}`;
export const SEND_MESSAGE_URL = () => API_URL + "contact/";
export const SEND_EMAIL_URL = () => API_URL + "trium_admin/notify/";
export const GET_MESSAGE_URL = (enterpriseYearlyId) =>
  API_URL + `contact/${enterpriseYearlyId}/`;
export const FETCH_BANNIEREPUB_URL = () => API_URL + `enterprises/bannieres/`;
export const FETCH_PARTENAIRES_URL = () => API_URL + `enterprises/partenaires/`;
export const FETCH_INFOSGLOBALES_URL = () =>
  API_URL + `enterprises/infosglobales/`;
export const FETCH_USER_BY_ID_URL = (id) => UPDATE_USER_URL(id);
export const FETCH_ENTERPRISE_CATS_URL = () => API_URL + `enterprises/bigcat/`;
export const FETCH_DEVIS_URL = () => API_URL + "devis/";
export const FETCH_FACTURE_URL = () => API_URL + "facture/";
export const GENERATE_DEVIS_URL = (devisId, enterpriseYearlyId) =>
  API_URL + `devis/${devisId}/${enterpriseYearlyId}/`;
export const GENERATE_FACTURE_URL = (factureId, enterpriseYearlyId) =>
  API_URL + `facture/${factureId}/${enterpriseYearlyId}/`;
export const UPDATE_ENTERPRISE_YEARLY_DOCUMENT_URL = (enterpriseYearlyId) =>
  FETCH_ENTERPRISE_YEARLY_BY_ID_URL(enterpriseYearlyId) + "add_document/";
export const VALIDATE_DOCUMENT_URL = (documentId) =>
  API_URL + `documents/${documentId}/validate/`;
export const GET_DOCUMENT_URL = (documentId) =>
  API_URL + `documents/${documentId}/`;
export const REFUSE_DOCUMENT_URL = (documentId) =>
  GET_DOCUMENT_URL(documentId) + "refuse/";
export const GET_DEVIS_TYPE_ID_URL = () => API_URL + "documents/devis/";
export const GET_FACTURE_TYPE_ID_URL = () => API_URL + "documents/facture/";
export const FETCH_OPTIONS_TREE_URL = (enterpriseYearlyId) =>
  API_URL + `options-commandes/${enterpriseYearlyId}/`;
export const FETCH_SCHOOLS_URL = () => API_URL + "users/schools/";
export const SET_READY_URL = (enterpriseYearlyId) =>
  API_URL + `enterprises/yearly/${enterpriseYearlyId}/ready`;

export const STATUS = {
  PENDING: "pending",
  FULFILLED: "fulfilled",
  IDLE: "idle",
  FAILED: "rejected",
};
