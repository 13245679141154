import React from "react";

import { FirstView } from "./FirstView";
import { Navbar2 } from "./NavBar2";
import { Forum } from "./Forum";
import { Entreprises } from "./Entreprises";
import { Ecoles } from "./Ecoles";
import { Partenaires } from "./Partenaires";
import { Contact } from "./Contact";
import { Acces } from "./Acces";

export const Vitrine = () => {
  return (
    <>
      <FirstView />
      <Navbar2 />
      <Forum />
      <Entreprises />
      <Ecoles />
      <Partenaires />
      <Acces />
      <Contact />
    </>
  );
};
