import {
  Button,
  Container,
  MobileStepper,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { AxiosResponse } from "axios";
import React, { FC, useEffect, useState } from "react";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchEnterpriseYearly } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { EmailForm } from "../Commons/EmailForm";
import { StatusGuard } from "../Commons/StatusGuard";
import { Email } from "../Commons/types";
import { EnterpriseProfile } from "../EnterpriseProfile";
import { ChooseOptionsTree } from "../UserDashboard/ChooseOptionsTree";
import { DocumentsUploadWrapped } from "../UserDashboard/DocumentsUpload";
import { UserProfileWrapper } from "../UserProfile";
import { DevisForm } from "./DevisForm";
import { DocumentsValidation } from "./DocumentsValidation";
import { TabPanel } from "../UserDashboard/UserDashboard";
import { FactureForm } from "./FactureForm";
import { SetReady } from "./SetReady";
import { ReductionSetter } from "./ReductionSetter";

function getSteps() {
  return [
    "Informations",
    "Choix des options",
    "Fichiers (Envoyés)",
    "Fichiers (Verifiés)",
    "Devis (Envoyé)",
    "Devis (Accepté)",
    "Paiement et Facture",
  ];
}

const EmailFormWrapper: FC<{ enterpriseYearlyId: number }> = (props) => {
  const [mail, setMail] = useState<Email>({
    to: "",
    from: "",
    subject: "",
    message: "",
  });
  const [getStatus, setGetStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    if (getStatus === STATUS.IDLE) {
      setGetStatus(STATUS.PENDING);
      API.getEmail(props.enterpriseYearlyId)
        .then((response: AxiosResponse<Email>) => {
          setGetStatus(STATUS.FULFILLED);
          setMail(response.data);
        })
        .catch(() => {
          setGetStatus(STATUS.FAILED);
        });
    }
  }, [getStatus, props.enterpriseYearlyId]);

  return (
    <StatusGuard status={getStatus}>
      <Typography variant={"h5"}>Envoyer un message</Typography>
      <EmailForm email={mail} />
    </StatusGuard>
  );
};

const AvancementStepper: FC<{ enterpriseYearly: EnterpriseYearly }> = (
  props
) => {
  const steps = getSteps();

  return (
    <Container>
      <Stepper activeStep={props.enterpriseYearly.status}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode; error?: boolean } =
            {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Container>
  );
};

const ManagementStepper: FC<{ enterpriseYearly: EnterpriseYearly }> = (
  props
) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    <EmailFormWrapper enterpriseYearlyId={props.enterpriseYearly.id} />,
    <UserProfileWrapper userId={props.enterpriseYearly.contact?.user} />,
    <EnterpriseProfile enterprise={props.enterpriseYearly.enterprise} />,
    <DevisForm enterpriseYearly={props.enterpriseYearly} />,
    <DocumentsUploadWrapped enterpriseYearly={props.enterpriseYearly} />,
    <DocumentsValidation enterpriseYearly={props.enterpriseYearly} />,
    <ChooseOptionsTree enterpriseYearly={props.enterpriseYearly} />,
  ];

  const handleNext = () => {
    setActiveStep((activeStep + 1) % steps.length);
  };

  const handleBack = () => {
    setActiveStep(activeStep === 0 ? steps.length - 1 : activeStep - 1);
  };
  const handleChange = (event: any, newValue: any) => {
    setActiveStep(newValue);
  };

  const DisplayTabs = () => {
    return (
      <>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={activeStep}
          onChange={handleChange}
          scrollButtons="on"
          aria-label="horizontal tabs"
          className="tabs-user-dashboard"
          style={{ marginBottom: "20px" }}
        >
          <Tab
            label="Message"
            id="horizontal-tab-0"
            aria-controls="horizontal-tabpanel-0"
          />
          <Tab
            label="Contact"
            id="horizontal-tab-1"
            aria-controls="horizontal-tabpanel-1"
          />
          <Tab
            label="Entreprise"
            id="horizontal-tab-2"
            aria-controls="horizontal-tabpanel-2"
          />
          <Tab
            label="Options"
            id="horizontal-tab-3"
            aria-controls="horizontal-tabpanel-3"
          />
          <Tab
            label="Documents"
            id="horizontal-tab-3"
            aria-controls="horizontal-tabpanel-3"
          />
          <Tab
            label="Devis et Facture"
            id="horizontal-tab-3"
            aria-controls="horizontal-tabpanel-3"
          />
          <Tab
            label="Entreprise prête"
            id="horizontal-tab-3"
            aria-controls="horizontal-tabpanel-3"
          />
          <Tab
            label="Documents (Cheat)"
            id="horizontal-tab-3"
            aria-controls="horizontal-tabpanel-3"
          />
        </Tabs>
        <Container>
          <Paper elevation={3} style={{ padding: "1.5rem" }}>
            <TabPanel value={activeStep} index={0}>
              <EmailFormWrapper
                enterpriseYearlyId={props.enterpriseYearly.id}
              />
            </TabPanel>
            <TabPanel index={activeStep} value={1}>
              <UserProfileWrapper
                userId={props.enterpriseYearly.contact?.user}
              />
            </TabPanel>
            <TabPanel index={activeStep} value={2}>
              <EnterpriseProfile
                enterprise={props.enterpriseYearly.enterprise}
              />
            </TabPanel>
            <TabPanel index={activeStep} value={3}>
              <ChooseOptionsTree enterpriseYearly={props.enterpriseYearly} />
            </TabPanel>
            <TabPanel index={activeStep} value={4}>
              <DocumentsValidation enterpriseYearly={props.enterpriseYearly} />
            </TabPanel>
            <TabPanel index={activeStep} value={5}>
              <div style={{ padding: "2%" }}>
                <ReductionSetter enterpriseYearly={props.enterpriseYearly} />
              </div>
              <br />
              <div style={{ padding: "2%" }}>
                <DevisForm enterpriseYearly={props.enterpriseYearly} />
              </div>
              <div style={{ padding: "2%" }}>
                <FactureForm enterpriseYearly={props.enterpriseYearly} />
              </div>
            </TabPanel>
            <TabPanel index={activeStep} value={6}>
              <SetReady enterpriseYearly={props.enterpriseYearly} />
            </TabPanel>
            <TabPanel index={activeStep} value={7}>
              <DocumentsUploadWrapped
                enterpriseYearly={props.enterpriseYearly}
              />
            </TabPanel>
          </Paper>
        </Container>
      </>
    );
  };

  const DisplayStepper = () => {
    return (
      <Container style={{ marginBottom: "2rem" }}>
        <Paper elevation={3}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            // className={classes.root}
            nextButton={
              <Button size="small" onClick={handleNext}>
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
          {steps[activeStep]}
        </Paper>
      </Container>
    );
  };
  return <DisplayTabs />;
};

export const EnterpriseYearlySinglePage: FC<{
  enterpriseYearlyId: number;
}> = (props) => {
  const fetchWrapper = useFetchEnterpriseYearly(props.enterpriseYearlyId);

  return contentFromStatus(
    fetchWrapper.fetchStatus,
    () => {
      // if (fetchWrapper.data) return <EnterpriseYearlyWrapped enterpriseYearly={fetchWrapper.data} />
      if (fetchWrapper.data)
        return (
          <>
            <AvancementStepper enterpriseYearly={fetchWrapper.data} />
            <Container>
              <ManagementStepper enterpriseYearly={fetchWrapper.data} />
            </Container>
          </>
        );
      return null;
    },
    () => {
      return <div>Error.</div>;
    }
  );
};
