import React, { useState } from "react";
import "./Contact.css";
import { API } from "../../api/rest";
import { useSnackbar } from "notistack";
import { emailRegex } from "../../helpers/utils";
import { SocialIcon } from "react-social-icons";

export const Contact = () => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [job, setJob] = useState("");
  const [jobError, setJobError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const onSendClicked = () => {
    const re = emailRegex;
    setEmailError("");
    setMessageError("");
    setJobError("");
    setNameError("");

    let hasError = false;
    if (!name) {
      setNameError("Ce champ doit être rempli.");
      hasError = true;
    }
    if (!job) {
      setJobError("Ce champ doit être rempli.");
      hasError = true;
    }

    if (!message) {
      setMessageError("Ce champ doit être rempli.");
      hasError = true;
    }

    if (!re.test(email)) {
      setEmailError("Cet e-mail est invalide.");
      hasError = true;
    }

    if (!hasError) {
      setEmailError("");
      setMessageError("");
      setJobError("");
      setNameError("");

      API.sendMessage({ name, job, message, email })
        .then(() => {
          enqueueSnackbar(
            "Votre message a été envoyé avec succès, nous vous contacterons sous peu.",
            { variant: "success" }
          );
          setEmail("");
          setJob("");
          setMessage("");
          setName("");
        })
        .catch(() => {
          enqueueSnackbar(
            "Une erreur s'est produite, merci de réessayer plus tard.",
            { variant: "error" }
          );
        });
    }
  };

  return (
    <div id="contact" className="contact-container-all">
      <div className="titre-contact-container">
        <div className="titre-contact">Contact</div>
        <div className="under-titre-contact" />
      </div>

      <div className="contact-form-container">
        <form name="contactForm" className="contact-form">
          <table className="contact-form-table">
            <tbody>
              <tr>
                <td>
                  <label>Nom</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="name"
                    className="long-contact-input"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <span className="error-contact-form" id="errorname">
                    {nameError}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>E-mail</label>
                </td>
                <td>
                  <input
                    type="email"
                    name="email"
                    className="long-contact-input"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <span className="error-contact-form" id="erroremail">
                    {emailError}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Profession (Étudiant, Entreprise)</label>
                </td>
                <td>
                  <input
                    type="text"
                    name="profession"
                    className="long-contact-input"
                    value={job}
                    onChange={(event) => setJob(event.target.value)}
                  />
                  <span className="error-contact-form" id="errorJob">
                    {jobError}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <label>Laissez-nous un message !</label>
                </td>
                <td>
                  <textarea
                    name="message"
                    className="long-contact-input contact-field-textarea"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <span className="error-contact-form" id="errormsg">
                    {messageError}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <button
                    type="button"
                    onClick={onSendClicked}
                    className="contact-button-send"
                  >
                    Envoyer
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>

      <div className="mail-reseaux-contact">
        <div>Vous pouvez aussi nous contacter directement par mail :</div>
        <a href="mailto:contact@forum-trium.com">contact@forum-trium.com</a>
        <div>Ou sur nos différents réseaux sociaux !</div>
        <div>
          <SocialIcon
            url="https://www.facebook.com/forumtrium"
            className="reseaux-sociaux-contact"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://www.instagram.com/forum_trium/?hl=fr"
            className="reseaux-sociaux-contact"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://fr.linkedin.com/company/forum-trium"
            className="reseaux-sociaux-contact"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
    </div>
  );
};
