import React, { FC, useState } from "react";
import { useFetchEnterprise } from "../../features/enterprises/enterprisesSlice";
import { contentFromStatus } from "../../helpers/displayHelpers";
import {
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
} from "@material-ui/core";
import { ImageUploadField } from "../UploadButton";
import { EditButton } from "../EditButton";
import { STATUS } from "../../constants/rest";
import { difference } from "../../helpers/patchHelper";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { Logo } from "../Commons/Logo";
import { Enterprise } from "../../features/enterprises/types";
import { EnterprisesYearlyListWrapper } from "../Commons/EnterprisesYearlyList";
import { EnterpriseProfile } from "components/EnterpriseProfile";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "300px",
    },
    button: {
      marginLeft: "200px",
      marginBottom: "100px",
    },
  })
);

export const EnterpriseSinglePageHelper: FC<{ enterprise: Enterprise }> = (
  props
) => {
  const [editMode, setEditMode] = useState(false);
  const [enterprise, setEnterprise] = useState(props.enterprise);
  const [updateStatus, setUpdateStatus] = useState(STATUS.IDLE);
  const [updateErrors, setUpdateErrors] = useState({} as any);
  const classes = useStyles();

  const handleEditButtonClicked = () => {
    setEditMode(!editMode);
  };

  const handleCancelButtonClicked = () => {
    setEditMode(!editMode);
    setEnterprise(props.enterprise);
    setUpdateErrors({});
  };

  const handleSaveButtonClicked = () => {
    if (updateStatus !== STATUS.PENDING) {
      setUpdateStatus(STATUS.PENDING);
      let delta = difference(enterprise, props.enterprise);
      delta.id = props.enterprise.id;

      API.updateEnterprise(delta)
        .then((response: AxiosResponse) => {
          setUpdateStatus(STATUS.FULFILLED);
          setEditMode(!editMode);
          setUpdateErrors({});
        })
        .catch((error: AxiosError) => {
          setUpdateStatus(STATUS.FAILED);
          setUpdateErrors(error.response?.data);
        });
    }
  };

  return (
    <div>
      <TextField
        id="outlined-margin-normal"
        margin="normal"
        variant="outlined"
        disabled={!editMode}
        value={enterprise.name}
        className={classes.textField}
        error={!!updateErrors?.name}
        helperText={updateErrors?.name}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            name: event.target.value,
          })
        }
      />
      <br />
      <TextField
        id="outlined-margin-normal"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Telephone"
        disabled={!editMode}
        value={enterprise.phone_number}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            phone_number: event.target.value,
          })
        }
        error={!!updateErrors?.phone_number}
        helperText={updateErrors?.phone_number}
      />
      <TextField
        id="outlined-margin-normal"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Adresse"
        disabled={!editMode}
        value={enterprise.address}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            address: event.target.value,
          })
        }
        error={!!updateErrors?.address}
        helperText={updateErrors?.address}
      />
      <br />
      <TextField
        id="outlined-margin-normal"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Ville"
        disabled={!editMode}
        value={enterprise.city}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            city: event.target.value,
          })
        }
        error={!!updateErrors?.city}
        helperText={updateErrors?.city}
      />
      <br />
      <TextField
        id="outlined-margin-normal"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Date de creation"
        disabled={!editMode}
        value={enterprise.creation_year}
        onChange={(event) =>
          setEnterprise({
            ...enterprise,
            creation_year: isNaN(parseInt(event.target.value))
              ? event.target.value
              : parseInt(event.target.value),
          })
        }
        error={!!updateErrors?.creation_year}
        helperText={updateErrors?.creation_year}
      />
      <br />
      <span className={classes.textField}>
        <ImageUploadField
          url={enterprise?.logo}
          name={"Logo"}
          label={"logo"}
          invisible={!editMode}
          onFileChange={(event: any) =>
            setEnterprise({
              ...enterprise,
              logo: event.target.files[0],
            })
          }
        />
      </span>

      <br />
      <EditButton
        editMode={editMode}
        onEdit={handleEditButtonClicked}
        onSave={handleSaveButtonClicked}
        onCancel={handleCancelButtonClicked}
      />
    </div>
  );
};

export const EnterpriseSinglePage: FC<{ enterpriseId: number }> = (props) => {
  const fetchWrapper = useFetchEnterprise(props.enterpriseId);

  return contentFromStatus(
    fetchWrapper.fetchStatus,
    () => {
      if (!fetchWrapper.data) return null;
      return (
        <>
          <Paper elevation={3} style={{ marginBottom: "1.2rem" }}>
            {/* <EnterpriseSinglePageHelper enterprise={fetchWrapper.data} /> */}
            <EnterpriseProfile enterprise={fetchWrapper.data} />
          </Paper>
          <Paper elevation={3} style={{ marginBottom: "1.2rem" }}>
            <EnterprisesYearlyListWrapper
              ids={fetchWrapper.data.enterprises_yearly}
            />
          </Paper>
        </>
      );
    },
    () => {
      return <div>Error</div>;
    }
  );
};
