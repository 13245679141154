// Small slice to fetch enterprise types

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from "@reduxjs/toolkit";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { EnterpriseType } from "./types";
import { useFetchData } from "../../api/hooks";

const enterpriseTypesAdapter = createEntityAdapter<EnterpriseType>();
type State = {
  fetchStatus: string;
  error: null | undefined | AxiosError<any>;
} & EntityState<EnterpriseType>;

const initialState = enterpriseTypesAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
}) as State;

const fetch = createAsyncThunk<
  EnterpriseType[],
  undefined,
  { rejectValue: AxiosError }
>("enterprises/types/fetch", async (arg: undefined, thunkAPI) => {
  return API.fetchEnterpriseTypes()
    .then((response: AxiosResponse<EnterpriseType>) => response.data)
    .catch((error: AxiosError) => thunkAPI.rejectWithValue(error));
});
export { fetch as fetchEnterpriseTypes };

const enterpriseTypesSlice = createSlice({
  name: "enterpriseTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      enterpriseTypesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const enterpriseTypesSelector = enterpriseTypesAdapter.getSelectors(
  (state: any) => state.enterpriseTypes
);
export const { selectAll: selectAllTypes } = enterpriseTypesSelector;
const fetchStatusSelector = (state: any) => state.enterpriseTypes.fetchStatus;
export { fetchStatusSelector as enterpriseTypesFetchStatusSelector };

export const enterpriseTypesFetchErrorSelector = (state: any) =>
  state.epochs.fetchError;

export default enterpriseTypesSlice.reducer;

//Hooks

export const useFetchTypes = (selector?: any) =>
  useFetchData<EnterpriseType[]>(
    selector ?? enterpriseTypesSelector.selectAll,
    fetchStatusSelector,
    enterpriseTypesFetchErrorSelector,
    fetch(),
    true
  );
