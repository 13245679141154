import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "300px",
      margin: theme.spacing(2.2),
    },
    button: {
      marginLeft: "200px",
      marginBottom: "100px",
    },
  })
);
