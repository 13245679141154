import logo from "../../media/logos/logo_trium.png";
import { animateScroll as scroll, Link as LinkScroll } from "react-scroll";
import "./NavBar2.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import { useFetchInfosGlobales } from "./InfosGlobalesSlice";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../api/apiSlice";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { selectCurrentUser } from "../users/usersSlice";

export const Navbar2 = () => {
  const fetchWrapperInfos = useFetchInfosGlobales();
  const infosGlobales = fetchWrapperInfos?.data[0];
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectCurrentUser);

  const DynamicButton = () => {
      return (
        <Link to={{ pathname: "https://entreprises.forum-trium.com/login" }} target="_blank" style={{ textDecoration: "none" }}>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            style={connexionButtonStyle}
          >
            Espace exposants
          </Button>
        </Link>
      );
  };


  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const connexionButtonStyle = {
    backgroundColor: "rgb(0,0,0)",
    color: "rgb(126, 192, 231)",
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      sticky="top"
      className="pt-0 pb-0 pl-0 custom-navbar"
    >
      <div className="brand-navbar">
        <img
          src={infosGlobales?.logo_trium_couleurs || logo}
          className="nav-logo"
          alt="Logo"
          onClick={scrollToTop}
        />
        <div className="txt-brand-forum">
          Forum
          <br />
          <b>Trium</b>
        </div>
      </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto custom-nav">
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="accueil"
              spy={true}
              smooth="easeOutQuad"
              offset={0}
              duration={800}
            >
              <div className="text-button-linkscroll">Accueil</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="forum"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Le Forum</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="entreprises"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Entreprises</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="ecoles"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Écoles</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="partenaires"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Nos partenaires</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="acces"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Accès</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar">
            <LinkScroll
              activeClass="active-linkscroll"
              to="contact"
              spy={true}
              smooth="easeOutQuad"
              offset={-80}
              duration={800}
            >
              <div className="text-button-linkscroll">Contact</div>
            </LinkScroll>
          </Navbar.Text>
          <Navbar.Text className="linkscroll-navbar-connexion">
            <DynamicButton />
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
