import React, { FC, useState } from "react";
import { User } from "../features/users/types";
import { TextField } from "@material-ui/core";
import { EditButton } from "./EditButton";
import { STATUS } from "../constants/rest";
import { API } from "../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { useStyles } from "../styles/ProfileStyle";
import { useFetchUser, usersActions } from "../features/users/usersSlice";
import { useDispatch } from "react-redux";
import { StatusGuard } from "./Commons/StatusGuard";
import { useSnackbar } from "notistack";
import { difference } from "../helpers/patchHelper";

type UserProfileProps = {
  user: User;
};

export const UserProfileWrapper: FC<{ userId: number }> = (props) => {
  const fetchWrapper = useFetchUser(props.userId);

  if (!fetchWrapper.data) return null;
  return (
    <StatusGuard status={fetchWrapper.fetchStatus}>
      <UserProfile user={fetchWrapper.data} />
    </StatusGuard>
  );
};

export const UserProfile = (props: UserProfileProps) => {
  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState(props.user);
  const [updateStatus, setUpdateStatus] = useState(STATUS.IDLE);
  const [updateErrors, setUpdateErrors] = useState({} as any);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleEditButtonClicked = () => {
    setEditMode(!editMode);
  };

  const handleCancelButtonClicked = () => {
    setEditMode(!editMode);
    setUser(props.user);
  };

  const handleSaveButtonClicked = () => {
    if (updateStatus !== STATUS.PENDING) {
      setUpdateStatus(STATUS.PENDING);
      let delta = difference(user, props.user);
      delta.id = props.user.id;
      API.updateUser(delta)
        .then((response: AxiosResponse) => {
          setUpdateStatus(STATUS.FULFILLED);
          setEditMode(!editMode);
          setUpdateErrors({});
          enqueueSnackbar("Informations sauvegardées", { variant: "success" });
          dispatch(usersActions.upsertOne(user));
        })
        .catch((error: AxiosError) => {
          setUpdateStatus(STATUS.FAILED);
          setUpdateErrors(error.response?.data);
          enqueueSnackbar("Erreur lors de la sauvegarde des informations", {
            variant: "error",
          });
        });
    }
  };

  return (
    <div>
      <TextField
        id="outlined-margin-normal-1"
        margin="normal"
        variant="outlined"
        disabled={true}
        value={user.email}
        className={classes.textField}
      />
      <br />
      <TextField
        id="outlined-margin-normal-2"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Prenom"
        disabled={!editMode}
        value={user.first_name}
        onChange={(event) =>
          setUser({
            ...user,
            first_name: event.target.value,
          })
        }
        error={!!updateErrors?.first_name}
        helperText={updateErrors?.first_name}
      />
      <TextField
        id="outlined-margin-normal-3"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Nom"
        disabled={!editMode}
        value={user.last_name}
        onChange={(event) =>
          setUser({
            ...user,
            last_name: event.target.value,
          })
        }
        error={!!updateErrors?.last_name}
        helperText={updateErrors?.last_name}
      />
      <br />
      <TextField
        id="outlined-margin-normal-4"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Fonction"
        disabled={!editMode}
        value={user.job}
        onChange={(event) =>
          setUser({
            ...user,
            job: event.target.value,
          })
        }
        error={!!updateErrors?.job}
        helperText={updateErrors?.job}
      />
      <br />
      <TextField
        id="outlined-margin-normal-5"
        margin="normal"
        variant="outlined"
        className={classes.textField}
        label="Telephone"
        disabled={!editMode}
        value={user.phone_number}
        onChange={(event) =>
          setUser({
            ...user,
            phone_number: event.target.value,
          })
        }
        error={!!updateErrors?.phone_number}
        helperText={updateErrors?.phone_number}
      />
      <br />
      <EditButton
        editMode={editMode}
        onEdit={handleEditButtonClicked}
        onSave={handleSaveButtonClicked}
        onCancel={handleCancelButtonClicked}
      />
    </div>
  );
};
