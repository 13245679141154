import "./Partenaires.css";

import { useFetchPartenaires } from "./PartenairesSlice";

export const Partenaires = () => {
  const fetchWrapper = useFetchPartenaires();
  const partenaires = fetchWrapper?.data;

  return (
    <div id="partenaires" className="partenaires-container-all">
      <div className="titre-partenaires-container">
        <div className="titre-partenaires">Nos partenaires</div>
        <div className="under-titre-partenaires" />
      </div>
      <div className="container-logos-partenaires">
        {partenaires.map((part) => (
          <a
            id={part?.id}
            key={part?.id}
            href={part?.website || null}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="logo-partenaire"
              style={{ backgroundImage: `url(${part?.logo})` }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};
