import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchData } from "../../api/hooks";

const infosGlobalesAdapter = createEntityAdapter();

const initialState = infosGlobalesAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetch = createAsyncThunk(
  "enterprises/infosglobales/fetch",
  async (_, thunkAPI) => {
    return API.fetchInfosGlobales()
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error));
  }
);
export { fetch as fetchInfosGlobales };

const infosGlobalesSlice = createSlice({
  name: "infosGlobales",
  initialState: initialState,
  reducers: {
    upsertOne: infosGlobalesAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      infosGlobalesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const infosGlobalesActions = infosGlobalesSlice.actions;

const infosGlobalesSelector = infosGlobalesAdapter.getSelectors(
  (state) => state.infosGlobales
);
export const { selectAll: selectAllInfosGlobales } = infosGlobalesSelector;

const selectFetchStatus = (state) => state.infosGlobales.fetchStatus;
export { selectFetchStatus as selectInfosGlobalesFetchStatus };

const selectFetchError = (state) => state.infosGlobales.fetchError;
export { selectFetchError as selectInfosGlobalesFetchError };

export default infosGlobalesSlice.reducer;

export const useFetchInfosGlobales = () =>
  useFetchData(
    infosGlobalesSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
