import {createAsyncThunk, createEntityAdapter, createSlice,} from "@reduxjs/toolkit";
import {STATUS} from "../../constants/rest";
import {API} from "../../api/rest";
import {AxiosError, AxiosResponse} from "axios";
import {useFetchData} from "../../api/hooks";

// Types
export type CatOption = {
  name: string;
  description: string;
};

// Adapter
const catoptionsAdapter = createEntityAdapter<CatOption>();

// State
const initialState = catoptionsAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  fetchError: null,
});

// Thunks
const fetch = createAsyncThunk<CatOption[]>(
  "catoptions/fetch",
  (_, thunkAPI) => {
    return API.fetchCatOptions()
      .then((results: AxiosResponse<CatOption[]>) => {
        return results.data;
      })
      .catch((error: AxiosError<CatOption[]>) => {
        thunkAPI.rejectWithValue(error);
      });
  }
);

// Slice
const catoptionsSlice = createSlice({
  name: "catoptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.rejected, (state) => {
      state.fetchStatus = STATUS.FAILED;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      catoptionsAdapter.setAll(state, action.payload);
      state.fetchStatus = STATUS.FULFILLED;
    });
  },
});

export const catoptionsActions = catoptionsSlice.actions;
export default catoptionsSlice.reducer;

// Selectors
export const catoptionsSelector = catoptionsAdapter.getSelectors(
  (state: any) => state.catoptions
);
export const selectFetchStatus = (state: any) => state.catoptions.fetchStatus;
export const selectFetchError = (state: any) => state.catoptions.fetchError;

// Hooks
export const useFetchCatOptions = () =>
  useFetchData<CatOption[]>(
    catoptionsSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
