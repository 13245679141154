import React, { FC, useState } from "react";
import {
  Document,
  DocumentType,
  EnterpriseYearly,
} from "../../features/enterprisesYearly/types";
import { IconButton, TextField, Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { API } from "../../api/rest";
import { useDispatch } from "react-redux";
import { fetchEnterpriseYearlyById } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { OpenDocumentButton } from "../Commons/OpenDocumentButton";
import ClearIcon from "@material-ui/icons/Clear";
import { useSnackbar } from "notistack";

export const DisplayDocument: FC<{
  document: Document;
  index: number;
  enterpriseYearlyId: number;
}> = (props) => {
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const validateDocumentClicked = (documentId: number, index: number) => {
    API.validateDocument(documentId)
      .then(() => {
        dispatch(fetchEnterpriseYearlyById(props.enterpriseYearlyId));
        enqueueSnackbar("Document accepté.", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
      });
  };

  const refuseDocumentClicked = (documentId: number, index: number) => {
    if (!reason) {
      setReasonError("Ce champ ne peut pas être vide.");
      return;
    } else {
      API.refuseDocument(documentId, reason)
        .then(() => {
          setReason("");
          setReasonError("");
          dispatch(fetchEnterpriseYearlyById(props.enterpriseYearlyId));
          enqueueSnackbar("Le document a été refusé.", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
        });
    }
  };

  if (props.document.validated) {
    return null;
  }
  return (
    <>
      <OpenDocumentButton
        documentId={props.document.id}
        label={props.document.type.name}
      />
      {/*<Link style={{display: "inline"}} onClick={() => window.open(props.document.file)}><Typography variant={"h5"}>{props.document.type.name}</Typography></Link>*/}
      <IconButton
        aria-label={"validate"}
        onClick={() => validateDocumentClicked(props.document.id, props.index)}
      >
        <CheckIcon style={{ color: "green", display: "inline" }} />
      </IconButton>
      <IconButton
        aria-label={"refuse"}
        onClick={() => refuseDocumentClicked(props.document.id, props.index)}
      >
        <ClearIcon style={{ color: "red", display: "inline" }} />
      </IconButton>
      <TextField
        id="reasons"
        label="Raisons du refus"
        value={reason}
        onChange={(event) => setReason(event.target.value)}
        error={Boolean(reasonError)}
        helperText={reasonError}
      />
    </>
  );
};

export const DocumentsValidation: React.FC<{
  enterpriseYearly: EnterpriseYearly;
}> = (props) => {
  const DisplayMissingDocument: FC<{ document_type: DocumentType }> = (
    props
  ) => {
    return (
      <div>
        <Typography variant={"h5"}>{props.document_type.name}: </Typography>
        <Typography variant={"h5"} style={{ color: "red" }}>
          MISSING
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Typography variant={"h5"}>Documents Validés</Typography>
      {props.enterpriseYearly.documents.map((document) => {
        return (
          <div>
            <OpenDocumentButton
              documentId={document.id}
              label={document.type.name}
            />
          </div>
        );
      })}
      {props.enterpriseYearly.documents
        .filter((document) => document.type.name !== "Devis")
        .map((document, index) => (
          <DisplayDocument
            enterpriseYearlyId={props.enterpriseYearly.id}
            document={document}
            index={index}
            key={index}
          />
        ))}
      {props.enterpriseYearly.needed_documents
        .filter((document_type) => {
          let found = false;
          props.enterpriseYearly.documents.forEach((doc) => {
            if (doc.type.id === document_type.id) found = true;
          });
          return !found;
        })
        .map((document_type) => (
          <DisplayMissingDocument document_type={document_type} />
        ))}
    </>
  );
};
