import React from "react";
import { API } from "../../api/rest";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { Link } from "@material-ui/core";

export const OpenDocumentButton: React.FC<{
  documentId: number;
  label: string;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const onDocumentLinkClicked = () => {
    API.getDocument(props.documentId)
      .then((response: AxiosResponse) => {
        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
      .catch((error: any) => {
        enqueueSnackbar("Vous n'avez pas le droit de visionner ce document.", {
          variant: "error",
        });
      });
  };

  return <Link onClick={onDocumentLinkClicked}>{props.label}</Link>;
};
