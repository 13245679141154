import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from "@reduxjs/toolkit";
import { CatOption } from "../catoptions/catoptionsSlice";
import { STATUS } from "constants/rest";
import { API } from "api/rest";
import { AxiosResponse } from "axios";
import { useFetchData } from "api/hooks";

type BigCatEnt = {
  id: EntityId;
  name: string;
};

export type OptionTree = {
  id: number;
  name: string;
  description: string;
  display_price: number;
  children: OptionTree[];
  is_terminal_node: boolean;
};

type Option = {
  id: EntityId;
  name: string;
  description: string;
  category: CatOption;
  cat_name: string;
  price: number;
  epoch: EntityId;
  autorisation: BigCatEnt[];
  priority: number;
};

const optionsAdapter = createEntityAdapter<Option>();

// Initial State

const initialState = optionsAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  fetchError: null,
});

// Thunks

const fetch = createAsyncThunk<Option[], number>("options/fetch", (epochId) => {
  return API.fetchOptions(epochId)
    .then((result: AxiosResponse<Option[]>) => {
      return result.data;
    })
    .catch(() => {});
});
// Slice

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      optionsAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state) => {
      state.fetchStatus = STATUS.FAILED;
    });
  },
});

export default optionsSlice.reducer;

// Selectors
export const optionsSelector = optionsAdapter.getSelectors(
  (state: any) => state.options
);
export const selectFetchStatus = (state: any) => state.options.fetchStatus;
export const selectFetchError = (state: any) => state.options.fetchError;

// Hooks
export const useFetchOptions = (epochId: number, selector?: any) =>
  useFetchData<Option[]>(
    selector ?? optionsSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(epochId),
    true
  );
