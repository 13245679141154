import React, { FC, useContext } from "react";
import { EpochContext } from "./AdminDashboard";
import { useFetchEpoch } from "../../features/epochs/epochsSlice";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { EnterprisesYearlyListWrapper } from "../Commons/EnterprisesYearlyList";
import { EnterpriseYearlySinglePage } from "./EnterpriseYearlySinglePage";

const EnterpriseYearlyList: FC = () => {
  const epochContext = useContext(EpochContext);
  const selectedEpoch = epochContext.selectedEpochId;
  const fetchWrapper = useFetchEpoch(selectedEpoch);

  return contentFromStatus(fetchWrapper.fetchStatus, () => {
    if (!fetchWrapper.data) return null;
    return <EnterprisesYearlyListWrapper ids={fetchWrapper.data.enterprises} />;
  });
};

export const EnterpriseYearlyPage: FC<{ enterpriseYearlyId: number | null }> = (
  props
) => {
  if (props.enterpriseYearlyId) {
    return (
      <EnterpriseYearlySinglePage
        enterpriseYearlyId={props.enterpriseYearlyId}
      />
    );
  }

  return <EnterpriseYearlyList />;
};
