import React, { useEffect, useState } from "react";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { contentFromStatus } from "../../helpers/displayHelpers";
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Document,
  EnterpriseYearly,
} from "../../features/enterprisesYearly/types";
import { OpenDocumentButton } from "../Commons/OpenDocumentButton";

type Facture = {
  id: number;
  name: string;
};

const UploadFacture: React.FC<{
  enterpriseYearlyId: number;
  oldFacture: Document | null;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [oldFactureURL, setOldFactureURL] = useState<null | Document>(
    props.oldFacture
  );
  const [factureTypeId, setFactureTypeId] = useState(-1);

  useEffect(() => {
    API.getFactureTypeId()
      .then((result: AxiosResponse) => {
        setFactureTypeId(result.data.type_id);
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
      });
  });

  const uploadHandler = (event: any) => {
    if (!event.target.files[0] || factureTypeId < 0) return;
    const document = {
      type_id: factureTypeId,
      file: event.target.files[0],
    };

    API.updateEnterpriseYearlyDocument(props.enterpriseYearlyId, document)
      .then(() => {
        enqueueSnackbar("Document transmis avec succès.", {
          variant: "success",
        });
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar("Erreur lors de la transmission du document.", {
          variant: "error",
        });
      });
  };

  const OldFactureButton = () => {
    if (oldFactureURL) {
      return (
        <Link
          onClick={() => {
            const fileURL = URL.createObjectURL(oldFactureURL);
            window.open(fileURL);
          }}
        >
          Old button
        </Link>
      );
    }
    return null;
  };

  return (
    <>
      <input
        accept=".pdf"
        id="contained-button-file"
        multiple
        type="file"
        style={{ display: "none" }}
        onChange={uploadHandler}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ marginTop: "2rem" }}
        >
          Upload
        </Button>
      </label>
      <OldFactureButton />
    </>
  );
};

const FactureSelector: React.FC<{
  factureList: Facture[];
  onChange: (value: number) => void;
}> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (event: any) => {
    setSelectedIndex(event.target.value);
    props.onChange(event.target.value);
  };

  if (selectedIndex < 0) return null;
  return (
    <FormControl>
      <InputLabel id="facture-selector-label">Facture</InputLabel>
      <Select
        labelId="facture-selector-label"
        id="facture-selector"
        value={selectedIndex}
        onChange={handleChange}
      >
        {props.factureList.map((facture, index) => {
          return <MenuItem value={index}>{facture.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const RedoFacture: React.FC<{ factureId: number }> = (props) => {
  return (
    <>
      <Typography variant={"h5"} style={{ color: "green" }}>
        Vous avez deja envoye une facture:{" "}
        <OpenDocumentButton
          documentId={props.factureId}
          label={"(voir la facture)"}
        />
      </Typography>
    </>
  );
};

export const FactureForm: React.FC<{ enterpriseYearly: EnterpriseYearly }> = (
  props
) => {
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);
  const [fetchError, setFetchError] = useState("");
  const [getFactureStatus, setGetFactureStatus] = useState(STATUS.IDLE);
  const [facturesList, setFacturesList] = useState<Facture[]>([]);
  const [factureIndex, setFactureIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onGenerateFactureClicked = () => {
    if (factureIndex >= 0 && getFactureStatus !== STATUS.PENDING) {
      setGetFactureStatus(STATUS.PENDING);
      API.generateFacture(
        facturesList[factureIndex].id,
        props.enterpriseYearly.id
      )
        .then((response: any) => {
          setGetFactureStatus(STATUS.FULFILLED);
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error: any) => {
          setGetFactureStatus(STATUS.FAILED);
          enqueueSnackbar(
            `Impossible de générer la facture : ${error.message}`,
            { variant: "error" }
          );
        });
    }
  };

  useEffect(() => {
    if (fetchStatus === STATUS.IDLE) {
      setFetchStatus(STATUS.PENDING);
      API.fetchFacture()
        .then((response: AxiosResponse<Facture[]>) => {
          setFetchStatus(STATUS.FULFILLED);
          setFacturesList(response.data);
        })
        .catch((error: AxiosError<Facture[]>) => {
          setFetchStatus(STATUS.FAILED);
          setFetchError(error.message);
          enqueueSnackbar(fetchError, { variant: "error" });
        });
    }
  }, [enqueueSnackbar, fetchError, fetchStatus]);

  return contentFromStatus(fetchStatus, () => {
    if (!props.enterpriseYearly.is_devis_accepted) {
      return (
        <Typography variant={"h5"}>
          Il faut que l'entreprise ait accepté le devis pour ajouter une
          facture.
        </Typography>
      );
    }
    if (props.enterpriseYearly.facture) {
      return <RedoFacture factureId={props.enterpriseYearly.facture.id} />;
    }
    return (
      <>
        <FactureSelector
          factureList={facturesList}
          onChange={(index) => {
            setFactureIndex(index);
          }}
        />

        <TextField label="Reduction" type={"number"} />
        <Button
          variant={"outlined"}
          onClick={onGenerateFactureClicked}
          style={{ marginLeft: "3rem" }}
        >
          Générer la facture
        </Button>
        <br />
        <UploadFacture
          enterpriseYearlyId={props.enterpriseYearly.id}
          oldFacture={props.enterpriseYearly.facture}
        />
      </>
    );
  });
};
