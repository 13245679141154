import "./Acces.css";

import { useFetchInfosGlobales } from "./InfosGlobalesSlice";

export const Acces = () => {
  const fetchWrapper = useFetchInfosGlobales();
  const infosGlobales = fetchWrapper?.data[0];

  return (
    <div id="acces" className="acces-container-all">
      <div className="titre-acces-container">
        <div className="titre-acces">Accès au Forum</div>
        <div className="under-titre-acces" />
      </div>
      <div className="container-img-acces">
        <a href={infosGlobales?.plan_acces} target="_blank" rel="noreferrer">
          <img
            src={infosGlobales?.plan_acces || null}
            className="plan-acces"
            alt="access map"
          />
        </a>
        {/* {infosGlobales?.infos_acces && (
                    <a href={infosGlobales?.infos_acces} target='_blank'>
                        <img src={infosGlobales?.infos_acces} className='infos-acces'/>
                    </a>)} */}
      </div>
    </div>
  );
};
