import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";

export type ImageUploadFieldProps = {
  invisible?: boolean;
  onFileChange: (event: any) => void;
  label: string;
  name: string;
  error?: string;
  url?: string | File;
};

export const ImageUploadField = ({
  invisible,
  label,
  error,
  url,
  onFileChange,
  name,
}: ImageUploadFieldProps) => {
  const [dynamicUrl, setDynamicUrl] = useState<string>("");

  const UploadButton = useCallback(
    () => (
      <>
        {!invisible && (
          <Button
            variant="contained"
            component="label"
            color="primary"
            style={{ width: "-webkit-fit-content", margin: "1rem" }}
          >
            Upload File
            <input
              type="file"
              id={`${label}-upload`}
              name="img"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "None" }}
            />
          </Button>
        )}
      </>
    ),
    [invisible, label, onFileChange]
  );

  const DisplayImage = useCallback(
    () => (
      <>
        {dynamicUrl !== "" && (
          <div>
            <Container>
              <img
                alt="logo entreprise"
                src={dynamicUrl}
                style={{
                  maxWidth: "128px",
                  maxHeight: "128px",
                  margin: "20px",
                }}
              />
            </Container>
          </div>
        )}
      </>
    ),
    [dynamicUrl]
  );

  useEffect(() => {
    if (!url) {
      return;
    } else if (typeof url !== "string") {
      setDynamicUrl(URL.createObjectURL(url));
    } else {
      setDynamicUrl(url);
    }
  }, [url]);

  return (
    <>
      <FormControl variant="outlined" error={!!error} disabled={!!invisible}>
        <InputLabel htmlFor="component-outlined" shrink>
          {name}
        </InputLabel>
        <OutlinedInput
          notched
          label={name}
          inputComponent={() => {
            return (
              <>
                <DisplayImage />
                <UploadButton />
              </>
            );
          }}
          id="component-outlined"
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
  );
};
