import React from "react";
import "./EntPublicPage.css";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { useFetchPublicEnterprise } from "../publicEnterprisesGrid/enterprisesPublicSlice";
import {
  enterpriseTypesSelector,
  useFetchTypes,
} from "../enterprises/enterpriseTypesSlice";
import { contentFromStatus } from "../../helpers/displayHelpers";

export const PublicPage = (props) => {
  //console.log(props.match.params);
  const fetchWrapper = useFetchPublicEnterprise(props.match.params.id);
  const fetchWrapperTypes = useFetchTypes(
    enterpriseTypesSelector.selectEntities
  );
  const ent = fetchWrapper?.data;
  const types = fetchWrapperTypes?.data;

  let history = useHistory();

  function handleClick() {
    history.push("/grilleEntreprises");
  }

  return contentFromStatus(
    fetchWrapper.fetchStatus,
    () => {
      if (!ent) return null;
      // console.log(types[ent?.enterprise_type])

      const websiteToShow = () => {
        if (ent?.website) {
          const myurl = new URL(ent?.website);
          return myurl.hostname;
        }

        return null;
      };

      return (
        <div className="single-page-ent-container">
          <Button variant="outlined" onClick={handleClick}>
            Retour à la liste
          </Button>
          <div className="single-page-ent-infos-all">
            <img
              src={ent?.picture_public || ent?.logo || null}
              alt="entreprise publique"
              className="single-page-ent-picture"
            />
            <div className="single-page-ent-infos">
              <div className="single-page-ent-name">{ent.name}</div>
              <img
                src={ent?.logo || null}
                className="single-page-ent-logo"
                alt="enterprise logo"
              />
              <a href={ent?.website || null} target="_blank" rel="noreferrer">
                {websiteToShow() || null}
              </a>
            </div>
          </div>
          <div className="single-page-ent-contenu-container">
            {ent?.text_presentation ? (
              <>
                <div className="single-page-ent-contenu-titre-container">
                  <div className="single-page-ent-contenu-titre">
                    Qui sommes-nous?
                  </div>
                  <div className="single-page-ent-contenu-under-titre" />
                </div>
                <span
                  className="single-page-ent-contenu"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                >
                  {ent.text_presentation}
                </span>
              </>
            ) : null}

            {ent?.text_stages ? (
              <>
                <div className="single-page-ent-contenu-titre-container">
                  <div
                    className="single-page-ent-contenu-titre"
                    style={{ paddingTop: "5%" }}
                  >
                    Nos offres de stages et d'emplois
                  </div>
                  <div className="single-page-ent-contenu-under-titre" />
                </div>
                <span
                  className="single-page-ent-contenu"
                  style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
                >
                  {ent.text_stages}
                </span>
              </>
            ) : null}
          </div>
        </div>
      );
    },
    () => {
      return <div>Erreur lors du chargement de cette page d'entreprise</div>;
    }
  );

  //return <div>Erreur lors du chargement de cette page d'entreprise</div>
};
