import React from "react";
import "./Entreprises.css";
import { EntreprisesCarrousel } from "./EntreprisesCarrousel";
import { EntreprisesTypesTab } from "./EntreprisesTypesTab";

export const Entreprises = () => {
  return (
    <div id="entreprises" className="entreprises-container-all">
      <div className="titre-entreprises-container">
        <div className="titre-entreprises">Entreprises</div>
        <div className="under-titre-entreprises" />
      </div>
      <EntreprisesCarrousel />
      <EntreprisesTypesTab />
    </div>
  );
};
