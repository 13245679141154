import { combineReducers } from "redux";

import counterReducer from "../features/counter/counterSlice";
import userReducer from "../features/users/usersSlice";
import enterprisesReducer from "../features/enterprises/enterprisesSlice";
import contactsReducer from "../features/contacts/contactsSlice";
import linksReducer from "../features/links/linksSlice";
import apiReducer from "../features/api/apiSlice";
import initializerReducer from "../features/initializer/initializerReducer";
import enterprisesYearlyReducer from "../features/enterprisesYearly/enterprisesYearlySlice";
import enterprisesPublicReducer from "../features/publicEnterprisesGrid/enterprisesPublicSlice";

import storage from "redux-persist/lib/storage";
import epochsReducer from "../features/epochs/epochsSlice";
import enterpriseTypesReducer from "../features/enterprises/enterpriseTypesSlice";
import catoptionsReducer from "../features/catoptions/catoptionsSlice";
import optionsReducer from "../features/options/optionsSlice";

import bannierePubReducer from "../features/vitrine/BannierePubSlice";
import partenairesReducer from "../features/vitrine/PartenairesSlice";
import infosGlobalesReducer from "../features/vitrine/InfosGlobalesSlice";
import enterpriseCatsReducer from "../features/enterprises/enterpriseCatsSlice";
import schoolsReducer from "../features/vitrine/SchoolsSlice";

const appReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
  enterprises: enterprisesReducer,
  contacts: contactsReducer,
  links: linksReducer,
  API: apiReducer,
  initializer: initializerReducer,
  epochs: epochsReducer,
  enterprisesYearly: enterprisesYearlyReducer,
  enterprisesPublic: enterprisesPublicReducer,
  enterpriseTypes: enterpriseTypesReducer,
  catoptions: catoptionsReducer,
  options: optionsReducer,
  bannierePub: bannierePubReducer,
  partenaires: partenairesReducer,
  infosGlobales: infosGlobalesReducer,
  enterpriseCats: enterpriseCatsReducer,
  schools: schoolsReducer,
});

export default function resetReducer(state, action) {
  if (action.type === "RESET_STORE") {
    storage.removeItem("persist:root");
    state = undefined;
  }

  return appReducer(state, action);
}
