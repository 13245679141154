import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchData, useFetchDataNoRedux } from "../../api/hooks";

// type EnterprisePublic = {
//     id: number,
//     name: string,
//     creation_year: number | string,
//     enterprise_type: number,
//     logo?: string,
// };
// export type {EnterprisePublic};

const enterprisesPublicAdapter = createEntityAdapter();

const initialState = enterprisesPublicAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetch = createAsyncThunk(
  "enterprises/public/fetch",
  async (_, thunkAPI) => {
    return API.fetchEnterprisesPublic()
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error));
  }
);
export { fetch as fetchEnterprisesPublic };

const enterprisesPublicSlice = createSlice({
  name: "enterprisesPublic",
  initialState: initialState,
  reducers: {
    upsertOne: enterprisesPublicAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      enterprisesPublicAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const enterprisesPublicActions = enterprisesPublicSlice.actions;

const enterprisesPublicSelector = enterprisesPublicAdapter.getSelectors(
  (state) => state.enterprisesPublic
);
export const { selectAll: selectAllEnterprisesPublic } =
  enterprisesPublicSelector;

const selectFetchStatus = (state) => state.enterprisesPublic.fetchStatus;
export { selectFetchStatus as selectEnterprisesPublicFetchStatus };

const selectFetchError = (state) => state.enterprisesPublic.fetchError;
export { selectFetchError as selectEnterprisesPublicFetchError };

export default enterprisesPublicSlice.reducer;

export const useFetchPublicEnterprises = () =>
  useFetchData(
    enterprisesPublicSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );

export const useFetchPublicEnterprise = (id) =>
  useFetchDataNoRedux(
    (state) => enterprisesPublicSelector.selectById(state, id),
    () => API.fetchEnterprisePublicById(id),
    enterprisesPublicActions.upsertOne
  );
