import React, { useEffect, useState } from "react";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { contentFromStatus } from "../../helpers/displayHelpers";
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import {
  Document,
  EnterpriseYearly,
} from "../../features/enterprisesYearly/types";
import { OpenDocumentButton } from "../Commons/OpenDocumentButton";

type Devis = {
  id: number;
  name: string;
};

const UploadDevis: React.FC<{
  enterpriseYearlyId: number;
  oldDevis: Document | null;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [oldDevisUrl, setOldDevisUrl] = useState<null | Document>(
    props.oldDevis
  );
  const [devisTypeId, setDevisTypeId] = useState(-1);

  useEffect(() => {
    API.getDevisTypeId()
      .then((result: AxiosResponse) => {
        setDevisTypeId(result.data.type_id);
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
      });
  });

  const uploadHandler = (event: any) => {
    if (!event.target.files[0] || devisTypeId < 0) return;
    const document = {
      type_id: devisTypeId,
      file: event.target.files[0],
    };

    API.updateEnterpriseYearlyDocument(props.enterpriseYearlyId, document)
      .then(() => {
        enqueueSnackbar("Document transmis avec succès.", {
          variant: "success",
        });
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar("Erreur lors de la transmission du document.", {
          variant: "error",
        });
      });
  };

  const OldDevisButton = () => {
    if (oldDevisUrl) {
      return (
        <Link
          onClick={() => {
            const fileURL = URL.createObjectURL(oldDevisUrl);
            window.open(fileURL);
          }}
        >
          Old button
        </Link>
      );
    }
    return null;
  };

  return (
    <>
      <input
        accept=".pdf"
        id="contained-button-file"
        multiple
        type="file"
        style={{ display: "none" }}
        onChange={uploadHandler}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          component="span"
          style={{ marginTop: "2rem" }}
        >
          Upload
        </Button>
      </label>
      <OldDevisButton />
    </>
  );
};

const DevisSelector: React.FC<{
  devisList: Devis[];
  onChange: (value: number) => void;
}> = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleChange = (event: any) => {
    setSelectedIndex(event.target.value);
    props.onChange(event.target.value);
  };

  if (selectedIndex < 0) return null;
  return (
    <FormControl>
      <InputLabel id="devis-selector-label">Devis</InputLabel>
      <Select
        labelId="devis-selector-label"
        id="devis-selector"
        value={selectedIndex}
        onChange={handleChange}
      >
        {props.devisList.map((devis, index) => {
          return <MenuItem value={index}>{devis.name}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const RedoDevis: React.FC<{ devisId: number }> = (props) => {
  return (
    <>
      <Typography variant={"h5"} style={{ color: "green" }}>
        Le devis a été accepté par l'entreprise.{" "}
        <OpenDocumentButton
          documentId={props.devisId}
          label={"(voir le devis)"}
        />
      </Typography>
    </>
  );
};

export const DevisForm: React.FC<{ enterpriseYearly: EnterpriseYearly }> = (
  props
) => {
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);
  const [fetchError, setFetchError] = useState("");
  const [getDevisStatus, setGetDevisStatus] = useState(STATUS.IDLE);
  const [devisList, setDevisList] = useState<Devis[]>([]);
  const [devisIndex, setDevisIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const onGenerateDevisClicked = () => {
    if (devisIndex >= 0 && getDevisStatus !== STATUS.PENDING) {
      setGetDevisStatus(STATUS.PENDING);
      API.generateDevis(devisList[devisIndex].id, props.enterpriseYearly.id)
        .then((response: any) => {
          setGetDevisStatus(STATUS.FULFILLED);
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error: any) => {
          setGetDevisStatus(STATUS.FAILED);
          enqueueSnackbar(`Impossible de générer le devis : ${error.message}`, {
            variant: "error",
          });
        });
    }
  };

  useEffect(() => {
    if (fetchStatus === STATUS.IDLE) {
      setFetchStatus(STATUS.PENDING);
      API.fetchDevis()
        .then((response: AxiosResponse<Devis[]>) => {
          setFetchStatus(STATUS.FULFILLED);
          setDevisList(response.data);
        })
        .catch((error: AxiosError<Devis[]>) => {
          setFetchStatus(STATUS.FAILED);
          setFetchError(error.message);
          enqueueSnackbar(fetchError, { variant: "error" });
        });
    }
  }, [enqueueSnackbar, fetchError, fetchStatus]);

  return contentFromStatus(fetchStatus, () => {
    if (props.enterpriseYearly.status < 4) {
      return (
        <Typography variant={"h5"}>
          Il faut accepter les fichiers avant de pouvoir générer un devis.
        </Typography>
      );
    }
    if (props.enterpriseYearly.is_devis_accepted) {
      return <RedoDevis devisId={props.enterpriseYearly.devis.id} />;
    }
    return (
      <>
        <DevisSelector
          devisList={devisList}
          onChange={(index) => {
            setDevisIndex(index);
          }}
        />
        <Button
          variant={"outlined"}
          onClick={onGenerateDevisClicked}
          style={{ marginLeft: "3rem" }}
        >
          Générer le devis
        </Button>
        <br />
        <UploadDevis
          enterpriseYearlyId={props.enterpriseYearly.id}
          oldDevis={props.enterpriseYearly.devis}
        />
      </>
    );
  });
};
