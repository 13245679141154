import React, { FC } from "react";
import { EntityId } from "@reduxjs/toolkit";
import { useFetchEnterprisesYearlyById } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { Link } from "react-router-dom";
import DataGrid, { Column } from "react-data-grid";
import { StatusIcon } from "../../features/enterprisesYearly/StatusIcon";

const columns = [
  {
    name: "Nom",
    key: "name",
    formatter: (value) => {
      if (value.row)
        return (
          <Link to={`/dashboard/enterprisesyearly/${value.row.id}`}>
            {value.row.name}
          </Link>
        );
      return null;
    },
  },
  {
    name: "Année",
    key: "year",
    formatter: (value) => {
      if (value.row) return <Link to={`/dashboard/`}>{value.row.year}</Link>;
    },
  },
  {
    name: "Statut",
    key: "status",
    formatter: (value) => {
      return <StatusIcon status={value.row.status} />;
    },
  },
] as Column<EnterpriseYearly>[];

const EnterprisesYearlyList: FC<{ enterprisesYearly: EnterpriseYearly[] }> = (
  props
) => {
  console.log(props);
  return (
    <div>
      <DataGrid columns={columns} rows={props.enterprisesYearly.sort((a, b) => a.name < b.name ? -1 : 1)} />
    </div>
  );
};

export const EnterprisesYearlyListWrapper: FC<{ ids: EntityId[] }> = (
  props
) => {
  const fetchWrapper = useFetchEnterprisesYearlyById(props.ids);

  return contentFromStatus(fetchWrapper.fetchStatus, () => {
    return (
      <EnterprisesYearlyList enterprisesYearly={fetchWrapper.data ?? []} />
    );
  });
};
