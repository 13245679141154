import React, { createContext, FC, useState } from "react";
import { Navbar } from "./Navbar";
import { Redirect } from "react-router-dom";
import { LinkPage } from "./LinkPage";
import { Container, Paper } from "@material-ui/core";
import { EnterprisePage } from "./EnterprisePage";
import { EnterpriseYearlyPage } from "./EnterpriseYearlyPage";
import { MyEnterprisesPage } from "./MyEnterprisesPage";
import { UserProfile } from "../UserProfile";
// import {UserProfileWrapper} from "../UserProfile";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/users/usersSlice";

export const EpochContext = createContext({
  selectedEpochId: 1,
  setSelectedEpochId: (() => {}) as any,
});

const Home: FC = () => {
  const user = useSelector(selectCurrentUser);
  if (!user) return null;
  return (
    <Paper elevation={3}>
      <UserProfile user={user} />
    </Paper>
  );
};

const AdminDashboard: FC<{ view?: string; id?: string }> = (props) => {
  const [selectedEpochId, setSelectedEpochId] = useState(1);
  const contextValue = { selectedEpochId, setSelectedEpochId };
  const view = props.view;
  const idWrapper = parseInt(props.id ?? "NaN");
  const id = isNaN(idWrapper) ? null : idWrapper;

  const renderFromView = (view?: string) => {
    switch (view) {
      case "home": {
        return <Home />;
      }
      case "links": {
        return <LinkPage />;
      }
      case "enterprises": {
        return <EnterprisePage enterpriseId={id} />;
      }
      case "myenterprises": {
        return <MyEnterprisesPage />;
      }
      case "enterprisesyearly": {
        return <EnterpriseYearlyPage enterpriseYearlyId={id} />;
      }
      default: {
        return <Redirect to="/dashboard/home" />;
      }
    }
  };

  return (
    <EpochContext.Provider value={contextValue}>
      <Navbar />
      <Container style={{ marginBottom: "2rem", minHeight: "75vh" }}>
        {renderFromView(view)}
      </Container>
    </EpochContext.Provider>
  );
};
export { AdminDashboard };
