import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectIsLoggedIn } from "../api/apiSlice";
import Button from "@material-ui/core/Button";

export const UserLogoutForm = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  const onLogoutClicked = () => {
    dispatch(logout());
  };

  let content = null;
  if (isLoggedIn) {
    content = (
      <div style={{ margin: "10px", float: "right" }}>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          onClick={onLogoutClicked}
        >
          Logout
        </Button>
      </div>
    );
  }

  return content;
};
