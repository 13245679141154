import React, { FC } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/users/usersSlice";
import { EnterprisesYearlyListWrapper } from "../Commons/EnterprisesYearlyList";

export const MyEnterprisesPage: FC = () => {
  const user = useSelector(selectCurrentUser);

  return <EnterprisesYearlyListWrapper ids={user?.enterprises_yearly ?? []} />;
};
