import React, { FC, useEffect, useState } from "react";
import {
  Document,
  DocumentType,
  EnterpriseYearly,
} from "../../features/enterprisesYearly/types";
import { OptionTree } from "../../features/options/optionsSlice";
import { useContentGuard } from "../../helpers/displayHelpers";
import { Button, Typography } from "@material-ui/core";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { OpenDocumentButton } from "../Commons/OpenDocumentButton";
import { useFetchEnterpriseYearly } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { EntityId } from "@reduxjs/toolkit";
import { STATUS } from "../../constants/rest";

const ToUpload: React.FC<{
  documentType: DocumentType;
  enterpriseYearlyId: number;
}> = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleUploadClicked = (id: number, event: any) => {
    if (!event.target.files[0]) return;
    const document = {
      type_id: id,
      file: event.target.files[0],
    };

    API.updateEnterpriseYearlyDocument(props.enterpriseYearlyId, document)
      .then(() => {
        enqueueSnackbar("Document transmis avec succès.", {
          variant: "success",
        });
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar("Erreur lors de la transmission du document.", {
          variant: "error",
        });
      });
  };

  const documentType = props.documentType;
  return (
    <div key={documentType.id}>
      <Typography variant={"h5"}>{documentType.name}</Typography>
      <Typography variant={"caption"}>{documentType.description}</Typography>
      <br />
      <input
        accept="*"
        id={`upload-file-${documentType.id}`}
        multiple
        type="file"
        style={{ display: "none" }}
        onChange={(event) => handleUploadClicked(documentType.id, event)}
      />
      <label htmlFor={`upload-file-${documentType.id}`}>
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
    </div>
  );
};

const Uploaded: FC<{ document: Document }> = (props) => {
  return null;
};

export const DocumentsUploadWrapped: React.FC<{
  enterpriseYearly: EnterpriseYearly;
}> = (props) => {
  // const fetchWrapper = useFetchOptions(props.enterpriseYearly.epoch.id, optionsSelector.selectEntities)
  const { enqueueSnackbar } = useSnackbar();
  const [options, setOptions] = useState<OptionTree[]>([]);
  const [fetchStatus, setFetchStatus] = useState(STATUS.IDLE);

  useEffect(() => {
    API.fetchOptionsTree(props.enterpriseYearly.id)
      .then((result: AxiosResponse<OptionTree[]>) => {
        setFetchStatus(STATUS.FULFILLED);
        setOptions(result.data);
      })
      .catch(() => {
        setFetchStatus(STATUS.FAILED);
        enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
      });
  }, [enqueueSnackbar, props.enterpriseYearly.id]);

  const content = useContentGuard(fetchStatus, options, (options) => {
    return (
      <>
        <Typography variant={"h5"}>Mes Documents</Typography>
        {props.enterpriseYearly.documents.map((document) => {
          return (
            <div>
              <OpenDocumentButton
                documentId={document.id}
                label={document.type.name}
              />
            </div>
          );
        })}
        {props.enterpriseYearly.needed_documents
          .filter((document_type) => {
            let canUpload = true;
            props.enterpriseYearly.documents.forEach((uploaded_document) => {
              if (
                uploaded_document.type.id === document_type.id &&
                uploaded_document.validated
              )
                canUpload = false;
            });
            return canUpload;
          })
          .map((documentType) => {
            return (
              <ToUpload
                documentType={documentType}
                enterpriseYearlyId={props.enterpriseYearly.id}
              />
            );
          })}
      </>
    );
  });

  return content();
};

export const DocumentsUpload: FC<{ enterpriseYearlyId: EntityId }> = (
  props
) => {
  const fetchWrapper = useFetchEnterpriseYearly(props.enterpriseYearlyId);
  const content = useContentGuard(
    fetchWrapper.fetchStatus,
    fetchWrapper.data,
    (data) => {
      return <DocumentsUploadWrapped enterpriseYearly={data} />;
    }
  );

  return content();
};
