import React, { FC } from "react";

export const StatusIcon: FC<{ status: number }> = (props) => {
  // 0 is OK, 1 is doing something and 2 is need validation.
  const aloneStatus = [0, 1, 2, 4, 5];
  const needHelpStatus = [3];
  const payStatus = [6];

  if (aloneStatus.includes(props.status)) {
    return <div style={{ color: "orange" }}>En attente</div>;
  }
  if (needHelpStatus.includes(props.status)) {
    return <div style={{ color: "red" }}>Necessite votre aide</div>;
  }
  if (payStatus.includes(props.status)) {
    return <div style={{ color: "purple" }}>En attente de paiement</div>;
  }

  return <div style={{ color: "green" }}>OK</div>;
};
