import React from "react";
import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import { useFetchEnterpriseYearly } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { useContentGuard } from "../../helpers/displayHelpers";
import { Container, Paper, Typography } from "@material-ui/core";
import { OpenDocumentButton } from "../Commons/OpenDocumentButton";
import { DisplayDocument } from "../AdminDashboard/DocumentsValidation";

export const DevisAndFactureWrapped: React.FC<{
  enterpriseYearly: EnterpriseYearly;
}> = (props) => {
  const Devis: React.FC = () => {
    if (props.enterpriseYearly.devis) {
      if (props.enterpriseYearly.is_devis_accepted) {
        return (
          <>
            <Typography variant={"caption"}>
              Vous avez accepte le devis.{" "}
            </Typography>
            <OpenDocumentButton
              documentId={props.enterpriseYearly.devis.id}
              label={"(Voir le devis)"}
            />
          </>
        );
      } else {
        return (
          <DisplayDocument
            document={props.enterpriseYearly.devis}
            index={0}
            enterpriseYearlyId={props.enterpriseYearly.id}
          />
        );
      }
    } else {
      return (
        <Typography variant={"caption"}>
          Vous n'avez pas encore recu de devis.
        </Typography>
      );
    }
  };

  const Facture: React.FC = () => {
    return <Typography>Facture</Typography>;
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: "1rem", marginBottom: "2rem" }}>
        <Devis />
        <Facture />
      </Paper>
    </Container>
  );
};

export const DevisAndFacture: React.FC<{ enterpriseYearlyId: number }> = (
  props
) => {
  const fetchWrapper = useFetchEnterpriseYearly(props.enterpriseYearlyId);
  const content = useContentGuard(
    fetchWrapper.fetchStatus,
    fetchWrapper.data,
    (data) => {
      return <DevisAndFactureWrapped enterpriseYearly={data} />;
    }
  );

  return content();
};
