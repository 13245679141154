import React, { FC } from "react";
import { LinksList } from "../../features/links/LinksList";
import { CreateLinkForm } from "../../features/links/CreateLinkForm";

export const LinkPage: FC = () => {
  return (
    <React.Fragment>
      <CreateLinkForm />
      <LinksList />
    </React.Fragment>
  );
};
