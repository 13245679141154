import React from "react";
//import bgClip from '../../media/clip.mp4';
import bgPicture from "../../media/photos_accueil/accueil.JPG";
import logoTrium from "../../media/logos/logo_blanc.png";
import { IoIosArrowDropdown } from "react-icons/io";
import { Link as LinkScroll } from "react-scroll";
import { useFetchInfosGlobales } from "./InfosGlobalesSlice";

import "./FirstView.css";

export const FirstView = () => {
  const fetchWrapperInfos = useFetchInfosGlobales();
  const infosGlobales = fetchWrapperInfos?.data[0];

  return (
    <>
      {/* <video autoPlay muted loop id='bg-vid' className='bg-vid'>
                <source src={bgClip} type='video/mp4'/>
                Format vidéo non supporté
            </video> */}

      {/* <img src={bgPicture} id='bg-vid' className='bg-vid'/> */}

      <div
        id="bg-vid"
        className="bg-vid"
        style={{ backgroundImage: `url(${bgPicture})` }}
      />

      <div className="main-info" id="accueil">
        <div className="main-info-container">
          <img src={logoTrium} alt="logo Trium" className="logo-trium" />
          <div className="text-main-info">
            <p>
              {infosGlobales?.num_edition || " "}
              <sup>ème</sup>
              édition
            </p>
            <p>{infosGlobales?.date || " "}</p>
            <p>{infosGlobales?.lieu || " "}</p>
          </div>
        </div>
        <LinkScroll to="forum" smooth="easeOutQuad" offset={-80} duration={800}>
          <p className="arrow-icon">
            <IoIosArrowDropdown />
          </p>
        </LinkScroll>
      </div>
    </>
  );
};
