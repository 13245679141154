import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectIsLoggedIn,
  selectLoginRequestStatus,
} from "../api/apiSlice";
import { STATUS } from "../../constants/rest";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { HashLink } from "react-router-hash-link";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { API } from "../../api/rest";
import { fetchCurrentUser } from "./usersSlice";
import ScrollToTopOnMount from "../../helpers/ScrollTop";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const UserLoginForm = () => {
  const dispatch = useDispatch();
  const loginRequestStatus = useSelector(selectLoginRequestStatus);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState(STATUS.IDLE);
  const [loginError, setLoginError] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const history = useHistory();

  const onEmailChanged = (e) => setEmail(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);

  const onLoginClicked = () => {
    if (loginStatus !== STATUS.PENDING) {
      setLoginStatus(STATUS.PENDING);
      API.login({ email, password })
        .then(async (response) => {
          setLoginStatus(STATUS.FULFILLED);
          dispatch(login(response.data));
          dispatch(fetchCurrentUser()).then(() => {
            history.push("/dashboard");
          });
        })
        .catch((error) => {
          setLoginStatus(STATUS.FAILED);
          setLoginError(true);
        });
    }
  };

  let content;
  const classes = useStyles();

  if (!isLoggedIn) {
    content = (
      <>
        <ScrollToTopOnMount />
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper} style={{ minHeight: "75vh" }}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                error={loginError}
                onChange={onEmailChanged}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={onPasswordChanged}
                error={loginError}
                helperText={
                  loginError ? "Mauvaise combinaison Email/Mot de passe" : ""
                }
              />
              {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                        /> */}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onLoginClicked}
              >
                Connexion
              </Button>
              <Grid container>
                {/*<Grid item xs>*/}
                {/*    <Link to={{*/}
                {/*            pathname: "/"*/}
                {/*          }} */}
                {/*          variant="body2">*/}
                {/*        Forgot password?*/}
                {/*    </Link>*/}
                {/*</Grid>*/}
                <Grid item>
                  <HashLink smooth to={"/#contact"}>
                    Vous n'avez pas de compte ? Contactez-nous !
                  </HashLink>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
      </>
    );

    return content;
  } else {
    history.push("/dashboard");
  }

  return null;
};
