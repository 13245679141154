import React from "react";
import { Button } from "@material-ui/core";

type EditButtonProps = {
  editMode: boolean;
  onEdit: () => void;
  onSave: () => void;
  onCancel: () => void;
};
export const EditButton = (props: EditButtonProps) => {
  if (props.editMode) {
    return (
      <div>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: "7px" }}
          onClick={props.onSave}
        >
          Sauvegarder
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: "7px" }}
          onClick={props.onCancel}
        >
          Annuler
        </Button>
      </div>
    );
  } else {
    return (
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: "7px" }}
        onClick={props.onEdit}
      >
        Modifier
      </Button>
    );
  }
};
