import { STATUS } from "../constants/rest";
import React, { ReactElement, useCallback } from "react";

export const contentFromStatus = (
  status: string,
  successCallback: () => ReactElement | null,
  errorCallback?: () => ReactElement | null
) => {
  if (status === STATUS.IDLE) {
    return <div>Loading...</div>;
  } else if (status === STATUS.PENDING) {
    return <div>Loading...</div>;
  } else if (status === STATUS.FULFILLED) {
    return successCallback();
  } else if (status === STATUS.FAILED) {
    if (errorCallback) {
      return errorCallback();
    }
  }

  return null;
};

export function useContentGuard<Type>(
  status: string,
  data: Type | null | undefined,
  successCallback: (data: Type) => React.ReactElement
) {
  return useCallback<() => React.ReactElement | null>(() => {
    if (status === STATUS.FULFILLED && data) {
      return successCallback(data);
    }
    return null;
  }, [status, data, successCallback]);
}
