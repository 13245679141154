import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/users/usersSlice";
import { UserLogoutForm } from "../../features/users/UserLogoutForm";
import { UserProfile } from "../UserProfile";
// import {EnterpriseDashboard} from "./EnterpriseDashboard";
// import {ChooseOptions} from './ChooseOptions';
import { DocumentsUploadWrapped } from "./DocumentsUpload";
import { EnterpriseProfile } from "../EnterpriseProfile";
import { UserIntro } from "./UserIntro";
// import {EnterpriseYearlyProfile} from './EnterpriseYearlyProfile';
import { contentFromStatus } from "../../helpers/displayHelpers";
import { useFetchEnterpriseYearly } from "../../features/enterprisesYearly/enterprisesYearlySlice";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Container } from "@material-ui/core";
import { DevisAndFactureWrapped } from "./DevisAndFacture";
import { ChooseOptionsTree } from "./ChooseOptionsTree";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`horizontal-tabpanel-${index}`}
      aria-labelledby={`horizontal-tab-${index}`}
      className="tab-panel-user-dashboard"
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const UserDashboard = (props) => {
  const user = useSelector(selectCurrentUser);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (user && user.contact) {
    return (
      <div style={{ minHeight: "80vh", margin: "30px" }}>
        <UserLogoutForm />

        <UserIntro user={user} enterpriseYearly={props?.enterpriseYearly} />
        <Container style={{ padding: "0px" }}>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            scrollButtons="on"
            aria-label="horizontal tabs"
            className="tabs-user-dashboard"
            style={{ marginBottom: "20px" }}
          >
            <Tab
              label="Utilisateur"
              id="horizontal-tab-0"
              aria-controls="horizontal-tabpanel-0"
            />
            <Tab
              label="Entreprise"
              id="horizontal-tab-1"
              aria-controls="horizontal-tabpanel-1"
            />
            <Tab
              label="Options"
              id="horizontal-tab-2"
              aria-controls="horizontal-tabpanel-2"
            />
            <Tab
              label="Mes Documents"
              id="horizontal-tab-3"
              aria-controls="horizontal-tabpanel-3"
            />
            <Tab
              label="Devis et Facture"
              id="horizontal-tab-3"
              aria-controls="horizontal-tabpanel-3"
            />
          </Tabs>
          <TabPanel value={value} index={0}>
            <UserProfile user={user} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <EnterpriseProfile
              enterprise={props?.enterpriseYearly?.enterprise}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
            <ChooseOptionsTree enterpriseYearly={props.enterpriseYearly} />
          </TabPanel>
          <TabPanel index={3} value={value}>
            <DocumentsUploadWrapped
              enterpriseYearly={props?.enterpriseYearly}
            />
          </TabPanel>
          <TabPanel index={4} value={value}>
            <DevisAndFactureWrapped
              enterpriseYearly={props?.enterpriseYearly}
            />
          </TabPanel>
        </Container>
      </div>
    );
  }
  return null;
};

export const UserDashboardWrapped = () => {
  const user = useSelector(selectCurrentUser);
  const fetchWrapperEntYearly = useFetchEnterpriseYearly(
    user?.contact?.enterprise_yearly
  );

  return contentFromStatus(
    fetchWrapperEntYearly.fetchStatus,
    () => {
      if (fetchWrapperEntYearly?.data)
        return <UserDashboard enterpriseYearly={fetchWrapperEntYearly?.data} />;
      return null;
    },
    () => {
      return <div>Error.</div>;
    }
  );
};
