import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchData } from "../../api/hooks";

const bannierePubAdapter = createEntityAdapter();

const initialState = bannierePubAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetch = createAsyncThunk(
  "enterprises/bannieres/fetch",
  async (_, thunkAPI) => {
    return API.fetchBannierePub()
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error));
  }
);
export { fetch as fetchBannierePub };

const bannierePubSlice = createSlice({
  name: "bannierePub",
  initialState: initialState,
  reducers: {
    upsertOne: bannierePubAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      bannierePubAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const bannierePubActions = bannierePubSlice.actions;

const bannierePubSelector = bannierePubAdapter.getSelectors(
  (state) => state.bannierePub
);
export const { selectAll: selectAllBannierePub } = bannierePubSelector;

const selectFetchStatus = (state) => state.bannierePub.fetchStatus;
export { selectFetchStatus as selectBannierePubFetchStatus };

const selectFetchError = (state) => state.bannierePub.fetchError;
export { selectFetchError as selectBannierePubFetchError };

export default bannierePubSlice.reducer;

export const useFetchBannieresPub = () =>
  useFetchData(
    bannierePubSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
