import {
  ADD_ENTERPRISE_URL,
  CREATE_ACCOUNT,
  CREATE_LINK_URL,
  FETCH_BANNIEREPUB_URL,
  FETCH_CATOPTIONS_URL,
  FETCH_CURRENT_USER_URL,
  FETCH_DEVIS_URL,
  FETCH_ENTERPRISE_BY_ID_URL,
  FETCH_ENTERPRISE_CATS_URL,
  FETCH_ENTERPRISE_PUBLIC_BY_ID_URL,
  FETCH_ENTERPRISE_TYPES_URL,
  FETCH_ENTERPRISE_YEARLY_BY_ID_URL,
  FETCH_ENTERPRISES_PUBLIC_URL,
  FETCH_ENTERPRISES_URL,
  FETCH_EPOCH_BY_ID_URL,
  FETCH_EPOCHS_URL,
  FETCH_FACTURE_URL,
  FETCH_INFOSGLOBALES_URL,
  FETCH_LINK_BY_ID_URL,
  FETCH_LINKS_URL,
  FETCH_OPTIONS_TREE_URL,
  FETCH_OPTIONS_URL,
  FETCH_PARTENAIRES_URL,
  FETCH_SCHOOLS_URL,
  FETCH_USER_BY_ID_URL,
  GENERATE_DEVIS_URL,
  GENERATE_FACTURE_URL,
  GET_DEVIS_TYPE_ID_URL,
  GET_DOCUMENT_URL,
  GET_FACTURE_TYPE_ID_URL,
  GET_MESSAGE_URL,
  LOGIN_URL,
  REFUSE_DOCUMENT_URL,
  SEND_EMAIL_URL,
  SEND_MESSAGE_URL,
  SET_READY_URL,
  UPDATE_ENTERPRISE_URL,
  UPDATE_ENTERPRISE_YEARLY_DOCUMENT_URL,
  UPDATE_ENTERPRISE_YEARLY_URL,
  UPDATE_USER_URL,
  VALIDATE_DOCUMENT_URL,
  VALIDATE_LINK,
} from "../constants/rest";
import {
  AddEnterpriseParam,
  CreateAccountParam,
  CreateLinkParam,
  LoginParam,
  UpdateEnterpriseParam,
  UpdateUserParam,
} from "./types";
import { EntityId } from "@reduxjs/toolkit";
import { AxiosPromise, AxiosResponse } from "axios";
import { EnterpriseYearly } from "../features/enterprisesYearly/types";
import { Email } from "../components/Commons/types";

const axios = require("axios");
const djangoAPI = axios.create();

export const API = {
  login: (account: LoginParam) => {
    return djangoAPI.post(LOGIN_URL(), account);
  },
  fetchCurrentUser: () => {
    return djangoAPI.get(FETCH_CURRENT_USER_URL());
  },
  fetchLinks: () => {
    return djangoAPI.get(FETCH_LINKS_URL());
  },
  // TODO: Fix this correctly.
  setToken: (token: string) => {
    if (token !== "") {
      djangoAPI.defaults.headers.common["Authorization"] = `JWT ${token}`;
    } else {
      delete djangoAPI.defaults.headers.common["Authorization"];
    }
  },
  hasToken: () => {
    return Boolean(djangoAPI.defaults.headers.common["Authorization"]);
  },
  nullifyToken: () => {
    djangoAPI.defaults.headers.common["Authorization"] = "";
    delete djangoAPI.defaults.headers.common["Authorization"];
  },
  printToken: () => {
    console.log("Token: " + djangoAPI.defaults.headers.common["Authorization"]);
  },
  createLink: (link: CreateLinkParam) => {
    return djangoAPI.post(CREATE_LINK_URL(), link);
  },
  fetchEnterprises: (epochId: number | undefined) => {
    return djangoAPI.get(FETCH_ENTERPRISES_URL(epochId));
  },
  fetchEnterprisesPublic: () => {
    return djangoAPI.get(FETCH_ENTERPRISES_PUBLIC_URL());
  },
  fetchEpochs: () => {
    return djangoAPI.get(FETCH_EPOCHS_URL());
  },
  fetchEpochById: (id: number) => {
    return djangoAPI.get(FETCH_EPOCH_BY_ID_URL(id));
  },
  fetchEnterpriseYearlyById: (id: EntityId) => {
    return djangoAPI.get(FETCH_ENTERPRISE_YEARLY_BY_ID_URL(id));
  },
  fetchEnterprisesYearlyById: (ids?: EntityId[]) => {
    const idsArray = ids ?? [];
    const promises = idsArray.map((id) => {
      return API.fetchEnterpriseYearlyById(id);
    });
    return Promise.all(promises).then((results: AxiosResponse[]) => {
      return {
        data: results.map((result) => result.data),
      };
    }) as AxiosPromise<EnterpriseYearly[]>;
  },
  fetchEnterpriseById: (id: number) => {
    return djangoAPI.get(FETCH_ENTERPRISE_BY_ID_URL(id));
  },
  fetchEnterprisePublicById: (id: number) => {
    return djangoAPI.get(FETCH_ENTERPRISE_PUBLIC_BY_ID_URL(id));
  },
  fetchLinkById: (id: number) => {
    return djangoAPI.get(FETCH_LINK_BY_ID_URL(id));
  },
  checkLink: (linkKey: string) => {
    return djangoAPI.get(VALIDATE_LINK(linkKey));
  },
  createAccount: (request: CreateAccountParam) => {
    return djangoAPI.post(CREATE_ACCOUNT(), request);
  },
  fetchEnterpriseTypes: () => {
    return djangoAPI.get(FETCH_ENTERPRISE_TYPES_URL());
  },
  addEnterprise: (enterprise: AddEnterpriseParam) => {
    return djangoAPI.post(ADD_ENTERPRISE_URL(), enterprise);
  },
  updateUser: (user: UpdateUserParam) => {
    let formData = new FormData();
    Object.keys(user).forEach((key) => {
      // @ts-ignore
      formData.append(key, user[key] as any);
    });

    return djangoAPI.patch(UPDATE_USER_URL(user.id), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateEnterprise: (enterprise: UpdateEnterpriseParam) => {
    let formData = new FormData();
    Object.keys(enterprise).forEach((key) => {
      // @ts-ignore
      formData.append(key, enterprise[key] as any);
    });

    return djangoAPI.patch(UPDATE_ENTERPRISE_URL(enterprise.id), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  updateEnterpriseYearly: (enterpriseYearly: any) => {
    return djangoAPI.patch(
      UPDATE_ENTERPRISE_YEARLY_URL(enterpriseYearly.id),
      enterpriseYearly,
      {}
    );
  },
  fetchCatOptions: () => {
    return djangoAPI.get(FETCH_CATOPTIONS_URL());
  },
  fetchOptions: (epochId: number) => {
    return djangoAPI.get(FETCH_OPTIONS_URL(epochId));
  },
  sendMessage: (message: {
    name: string;
    job: string;
    message: string;
    email: string;
  }) => {
    return djangoAPI.post(SEND_MESSAGE_URL(), message);
  },
  sendEmail: (email: Email) => {
    console.log(email);
    return djangoAPI.post(SEND_EMAIL_URL(), email);
  },
  getEmail: (enterpriseYearlyId: number) => {
    return djangoAPI.get(GET_MESSAGE_URL(enterpriseYearlyId));
  },
  fetchBannierePub: () => {
    return djangoAPI.get(FETCH_BANNIEREPUB_URL());
  },
  fetchPartenaires: () => {
    return djangoAPI.get(FETCH_PARTENAIRES_URL());
  },
  fetchInfosGlobales: () => {
    return djangoAPI.get(FETCH_INFOSGLOBALES_URL());
  },
  fetchUserById: (id: number) => {
    return djangoAPI.get(FETCH_USER_BY_ID_URL(id));
  },
  fetchEnterpriseCats: () => {
    return djangoAPI.get(FETCH_ENTERPRISE_CATS_URL());
  },
  fetchDevis: () => {
    return djangoAPI.get(FETCH_DEVIS_URL());
  },
  fetchFacture: () => {
    return djangoAPI.get(FETCH_FACTURE_URL());
  },
  generateDevis: (devisId: number, enterpriseYearlyId: number) => {
    return djangoAPI.get(GENERATE_DEVIS_URL(devisId, enterpriseYearlyId), {
      responseType: "blob",
    });
  },
  generateFacture: (factureId: number, enterpriseYearlyId: number) => {
    return djangoAPI.get(GENERATE_FACTURE_URL(factureId, enterpriseYearlyId), {
      responseType: "blob",
    });
  },
  updateEnterpriseYearlyDocument: (
    enterpriseYearlyId: number,
    document: { type_id: number; file: File }
  ) => {
    let formData = new FormData();
    formData.append("type_id", document.type_id.toString());
    formData.append("file", document.file);

    return djangoAPI.post(
      UPDATE_ENTERPRISE_YEARLY_DOCUMENT_URL(enterpriseYearlyId),
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  validateDocument: (documentId: number) => {
    return djangoAPI.get(VALIDATE_DOCUMENT_URL(documentId));
  },
  getDocument: (documentId: number) => {
    return djangoAPI.get(GET_DOCUMENT_URL(documentId), {
      responseType: "blob",
    });
  },
  refuseDocument: (documentId: number, reason: string) => {
    return djangoAPI.post(REFUSE_DOCUMENT_URL(documentId), { reason: reason });
  },
  getDevisTypeId: () => {
    return djangoAPI.get(GET_DEVIS_TYPE_ID_URL());
  },
  getFactureTypeId: () => {
    return djangoAPI(GET_FACTURE_TYPE_ID_URL());
  },
  fetchOptionsTree: (enterpriseYearlyId: number) => {
    return djangoAPI.get(FETCH_OPTIONS_TREE_URL(enterpriseYearlyId));
  },
  fetchSchools: () => {
    return djangoAPI.get(FETCH_SCHOOLS_URL());
  },
  setReady: (enterpriseYearlyId: number) => {
    return djangoAPI.get(SET_READY_URL(enterpriseYearlyId));
  },
};
