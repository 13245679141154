import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { STATUS } from "../../constants/rest";
import { useFetchData } from "../../api/hooks";
import { API } from "../../api/rest";
import { AxiosError, AxiosResponse } from "axios";

interface EnterpriseCat {
  id: number;
  name: string;
}

const enterpriseCatAdapter = createEntityAdapter<EnterpriseCat>();

const fetch = createAsyncThunk<
  EnterpriseCat[],
  undefined,
  { rejectValue: AxiosError }
>("enterpriseCats/fetch", (_, thunkAPI) => {
  console.log("Creating fetch hook...");

  return API.fetchEnterpriseCats()
    .then((result: AxiosResponse<EnterpriseCat[]>) => {
      return result.data;
    })
    .catch((error: AxiosError<EnterpriseCat[]>) => {
      thunkAPI.rejectWithValue(error);
    });
});

const initialState = enterpriseCatAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  fetchError: null as AxiosError<EnterpriseCat[]> | null | undefined,
});

const enterpriseCatSlice = createSlice({
  name: "enterpriseCats",
  initialState,
  reducers: {
    setAll: enterpriseCatAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      enterpriseCatAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.fetchError = action.payload;
    });
  },
});

export default enterpriseCatSlice.reducer;
export const enterpriseCatActions = enterpriseCatSlice.actions;
export const enterpriseCatSelector = enterpriseCatAdapter.getSelectors(
  (state: any) => state.enterpriseCats
);
const selectFetchError = (state: any) => state.enterpriseCats.fetchError;
const selectFetchStatus = (state: any) => state.enterpriseCats.fetchStatus;
export { selectFetchStatus as selectEnterpriseCatsFetchStatus };

export const useFetchEnterpriseCats = () => {
  return useFetchData<EnterpriseCat[]>(
    enterpriseCatSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
};
