import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEnterprises } from "./enterprisesSlice";
import {
  enterpriseTypesFetchStatusSelector,
  enterpriseTypesSelector,
  fetchEnterpriseTypes,
} from "./enterpriseTypesSlice";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import {
  Button,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useFetchEnterpriseCats } from "./enterpriseCatsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
  },
  title: {
    marginBottom: theme.spacing(1),
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
  },
  addButton: {
    height: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export const AddEnterpriseForm = () => {
  const [name, setName] = useState("");
  const [typeId, setTypeId] = useState(1);
  const [catId, setCatId] = useState(1);
  const [addStatus, setAddStatus] = useState(STATUS.IDLE);
  const [, setAddError] = useState("");
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const enterpriseTypes = useSelector(enterpriseTypesSelector.selectAll);
  const fetchStatus = useSelector(enterpriseTypesFetchStatusSelector);
  const fetchEnterpriseCatWrapper = useFetchEnterpriseCats();

  useEffect(() => {
    if (fetchStatus === STATUS.IDLE) {
      dispatch(fetchEnterpriseTypes());
    }
  }, [dispatch, enterpriseTypes, fetchStatus]);

  useEffect(() => {
    if (enterpriseTypes.length > 0) {
      setTypeId(enterpriseTypes[0].id);
    }
  }, [enterpriseTypes]);

  useEffect(() => {
    if (fetchEnterpriseCatWrapper.data.length > 0) {
      setCatId(fetchEnterpriseCatWrapper.data[0].id);
    }
  }, [fetchEnterpriseCatWrapper.data]);

  const onNameChanged = (e) => setName(e.target.value);
  const onTypeChanged = (e) => setTypeId(e.target.value);
  const onCatChanged = (e) => setCatId(e.target.value);

  const onAddClicked = () => {
    if (
      fetchStatus === STATUS.FULFILLED &&
      name &&
      addStatus !== STATUS.PENDING &&
      fetchEnterpriseCatWrapper.data
    ) {
      const enterprise = {
        name,
        typeId: typeId >= 0 ? typeId : enterpriseTypes[0].id,
        catId: catId >= 0 ? catId : fetchEnterpriseCatWrapper.data[0].id,
      };

      setAddStatus(STATUS.PENDING);
      API.addEnterprise(enterprise)
        .then((response) => {
          console.log(response.data);
          setAddStatus(STATUS.FULFILLED);
          setName("");
          dispatch(fetchEnterprises());
          enqueueSnackbar("Entreprise créée.", { variant: "success" });
        })
        .catch((error) => {
          setAddStatus(STATUS.FAILED);
          setAddError(error.message);
          enqueueSnackbar(error.message, { variant: "error" });
        });
    }
  };

  let content = <div>Loading...</div>;
  if (enterpriseTypes && fetchEnterpriseCatWrapper.data) {
    content = [
      <div className={classes.root}>
        <Typography variant="h5" className={classes.title}>
          Ajouter une entreprise :
        </Typography>
        <TextField
          required
          className={classes.formControl}
          variant={"outlined"}
          onChange={onNameChanged}
          value={name}
          label={"Nom"}
        >
          Name
        </TextField>

        {/* <FormControl className={classes.formControl} variant={"outlined"}>
          <InputLabel>Secteur</InputLabel>
          <Select value={typeId} onChange={onTypeChanged}>
            {enterpriseTypes.map((enterpriseType) => {
              return (
                <MenuItem key={enterpriseType.id} value={enterpriseType.id}>
                  {enterpriseType.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl> */}

        <TextField
          select
          label={"Secteur"}
          variant={"outlined"}
          value={typeId}
          onChange={onTypeChanged}
          className={classes.formControl}
        >
          {enterpriseTypes.map((enterpriseType) => {
            return (
              <MenuItem key={enterpriseType.id} value={enterpriseType.id}>
                {enterpriseType.name}
              </MenuItem>
            );
          })}
        </TextField>

        {/* <FormControl className={classes.formControl} variant={"outlined"}>
          <InputLabel>Type</InputLabel>
          <Select value={catId} onChange={onCatChanged}>
            {fetchEnterpriseCatWrapper.data.map((enterpriseCat) => {
              return (
                <MenuItem key={enterpriseCat.id} value={enterpriseCat.id}>
                  {enterpriseCat.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl> */}

        <TextField
          select
          label={"Type"}
          variant={"outlined"}
          value={catId}
          onChange={onCatChanged}
          className={classes.formControl}
        >
          {fetchEnterpriseCatWrapper.data.map((enterpriseCat) => {
            return (
              <MenuItem key={enterpriseCat.id} value={enterpriseCat.id}>
                {enterpriseCat.name}
              </MenuItem>
            );
          })}
        </TextField>

        <br />
        <Button
          type="button"
          onClick={onAddClicked}
          variant={"contained"}
          className={classes.addButton}
        >
          Ajouter
        </Button>
      </div>,
    ];
  }
  return content;
};
