import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from "@reduxjs/toolkit";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { EnterpriseYearly } from "./types";
import { AxiosError, AxiosResponse } from "axios";
import { useFetchDataNoRedux } from "../../api/hooks";

const enterprisesYearlyAdapter = createEntityAdapter<EnterpriseYearly>({});

const initialState = enterprisesYearlyAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetchById = createAsyncThunk<EnterpriseYearly, number>(
  "enterprisesYearly/fetchById",
  async (id, thunkAPI) => {
    return API.fetchEnterpriseYearlyById(id)
      .then((response: AxiosResponse<EnterpriseYearly>) => response.data)
      .catch((error: AxiosError<EnterpriseYearly>) => error);
  }
);
export { fetchById as fetchEnterpriseYearlyById };

const enterprisesYearlySlice = createSlice({
  name: "enterprisesYearly",
  initialState,
  reducers: {
    addOne: enterprisesYearlyAdapter.addOne,
    upsertOne: enterprisesYearlyAdapter.upsertOne,
    upsertMany: enterprisesYearlyAdapter.upsertMany,
    updateOne: enterprisesYearlyAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchById.fulfilled, (state, action) => {
      enterprisesYearlyAdapter.upsertOne(state, action.payload);
    });
  },
});

export const enterprisesYearlySelector = enterprisesYearlyAdapter.getSelectors(
  (state: any) => state.enterprisesYearly
);
export const enterpriseYearlyActions = enterprisesYearlySlice.actions;
export const selectEnterprisesYearlyLookupTable = (state: any) =>
  state.enterprisesYearly.entities;

export default enterprisesYearlySlice.reducer;

// Selectors

export const selectEnterprisesYearlyByIds =
  (ids?: EntityId[]) => (state: any) => {
    const idsArray = ids ?? [];
    const entities = idsArray.map((id) => {
      return state.enterprisesYearly.entities[id];
    });
    return entities.includes(undefined) ? undefined : entities;
  };

// Hooks

export const useFetchEnterpriseYearly = (id: EntityId) =>
  useFetchDataNoRedux<EnterpriseYearly>(
    (state: any) => enterprisesYearlySelector.selectById(state, id),
    () => API.fetchEnterpriseYearlyById(id),
    enterpriseYearlyActions.upsertOne
  );

export const useFetchEnterprisesYearlyById = (ids?: EntityId[]) =>
  useFetchDataNoRedux<EnterpriseYearly[]>(
    selectEnterprisesYearlyByIds(ids),
    () => API.fetchEnterprisesYearlyById(ids),
    enterpriseYearlyActions.upsertMany
  );
