import React from "react";
import "./Footer.css";
import logoTrium from "../../media/logos/logo_trium.png";

import { useFetchInfosGlobales } from "../vitrine/InfosGlobalesSlice";
import { SocialIcon } from "react-social-icons";

export const Footer = () => {
  const fetchWrapperInfos = useFetchInfosGlobales();
  const infosGlobales = fetchWrapperInfos?.data[0];

  return (
    <div className="footer-container">
      <div className="footer-line-1">
        <img
          src={infosGlobales?.logo_trium_couleurs || logoTrium}
          className="logo-footer"
          alt="logo Trium"
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a
            href={infosGlobales?.mentions_legales || null}
            target="_blank"
            style={{ color: "black", paddingBottom: "5px" }}
            rel="noreferrer"
          >
            Mentions légales
          </a>
          <a href="/" style={{ color: "black" }}>
            Retour à la page d'accueil
          </a>
        </div>
        <div>
          <SocialIcon
            url="https://www.facebook.com/forumtrium"
            className="reseaux-sociaux-footer"
            fgColor="white"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://www.instagram.com/forum_trium/?hl=fr"
            className="reseaux-sociaux-footer"
            fgColor="white"
            target="_blank"
            rel="noopener noreferrer"
          />
          <SocialIcon
            url="https://fr.linkedin.com/company/forum-trium"
            className="reseaux-sociaux-footer"
            fgColor="white"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
      </div>
      <div className="c-forum-trium">© ForumTrium 2021</div>
    </div>
  );
};
