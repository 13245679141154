import React, { FC, useContext, useEffect, useState } from "react";
import { Epoch, useFetchEpoch, useFetchEpochs } from "./epochsSlice";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { EntityId } from "@reduxjs/toolkit";
import { EpochContext } from "../../components/AdminDashboard/AdminDashboard";
import { STATUS } from "../../constants/rest";
import { contentFromStatus } from "../../helpers/displayHelpers";

export const EpochSelector: FC<{ className?: string }> = (props) => {
  const [anchor, setAnchor] = useState(null);
  const fetchWrapper = useFetchEpochs();
  const epochs = fetchWrapper.data;
  const { selectedEpochId, setSelectedEpochId } = useContext(EpochContext);
  const selectedEpochWrapper = useFetchEpoch(selectedEpochId);
  const selectedEpoch = selectedEpochWrapper.data;

  // TODO: Refactor to use it on promise return?
  useEffect(() => {
    if (
      fetchWrapper.fetchStatus === STATUS.FULFILLED &&
      selectedEpochId < 0 &&
      fetchWrapper.data
    ) {
      setSelectedEpochId(fetchWrapper.data[0].id);
    }
  }, [fetchWrapper, selectedEpochId, setSelectedEpochId]);

  return contentFromStatus(fetchWrapper.fetchStatus, () => {
    const toggleMenu = (event: any | null) => {
      setAnchor(event?.target);
    };
    const handleMenuItemClicked = (epochId: EntityId) => {
      setSelectedEpochId(epochId);
      toggleMenu(null);
    };

    const epochItem = (epoch: Epoch) => {
      return (
        <MenuItem
          key={epoch.id}
          onClick={() => handleMenuItemClicked(epoch.id)}
        >
          {epoch.name}
        </MenuItem>
      );
    };

    const renderedEpochs = epochs?.map((epoch) => epochItem(epoch)) ?? [];

    if (!selectedEpoch || !epochs?.length) {
      return null;
    }

    return (
      <div className={props.className}>
        <Button
          variant="contained"
          disableElevation
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={toggleMenu}
        >
          {selectedEpoch.name}
        </Button>
        <Menu keepMounted open={Boolean(anchor)} anchorEl={anchor}>
          {renderedEpochs}
        </Menu>
      </div>
    );
  });
};
