import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import React from "react";
import { Button, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { enterpriseYearlyActions } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { API } from "../../api/rest";

export const SetReady: React.FC<{ enterpriseYearly: EnterpriseYearly }> = (
  props
) => {
  const dispatch = useDispatch();

  const setReadyClicked = () => {
    API.setReady(props.enterpriseYearly.id).then(() => {
      dispatch(
        enterpriseYearlyActions.upsertOne({
          ...props.enterpriseYearly,
          is_ready: true,
        })
      );
    });
  };

  if (props.enterpriseYearly.is_ready) {
    return <Typography>L'entreprise participe au Forum Trium !</Typography>;
  } else if (props.enterpriseYearly.facture) {
    return (
      <Button variant={"outlined"} onClick={setReadyClicked}>
        Ajouter au site
      </Button>
    );
  } else {
    return (
      <Typography>
        Il manque encore des elements pour pouvoir accepter l'entreprise.
      </Typography>
    );
  }
};
