import React from "react";
import { Epoch } from "./epochsSlice";

type EpochStatusProps = {
  epoch?: Epoch;
};
export const EpochStatus = (props: EpochStatusProps) => {
  const epoch = props.epoch;

  if (!epoch) {
    return null;
  }

  if (epoch.is_active) {
    return <div>Epoch {epoch.name} selected.</div>;
  }
  return <div>Epoch is not active! You can't change its data.</div>;
};
