import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

const contactsAdapter = createEntityAdapter();
// const initialState = contactsAdapter.getInitialState();
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

export const fetchContacts = createAsyncThunk(
  "contacts/fetchContacts",
  async () => {
    await new Promise((r) => setTimeout(r, 2000));
    const response = {
      contacts: [{ id: 0, first_name: "FooDoo", last_name: "Bar" }],
    };
    return response.contacts;
  }
);

const contactsSlice = createSlice({
  name: "contacts",
  initialState: initialState,
  extraReducers: {
    [fetchContacts.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchContacts.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchContacts.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectAllContacts = (state) => state.contacts.data;

export default contactsSlice.reducer;
