import React, { FC } from "react";
import { AddEnterpriseForm } from "features/enterprises/AddEnterpriseForm";
import { EnterprisesList } from "features/enterprises/EnterprisesList";
import { makeStyles, Paper } from "@material-ui/core";
import { EnterpriseSinglePage } from "./EnterpriseSinglePage";

const useStyle = makeStyles((theme) => ({
  fragment: {
    marginBottom: theme.spacing(2),
  },
}));

export const EnterprisePage: FC<{ enterpriseId: number | null }> = (props) => {
  const classes = useStyle();
  if (props.enterpriseId) {
    return <EnterpriseSinglePage enterpriseId={props.enterpriseId} />;
  }

  return (
    <React.Fragment>
      <Paper elevation={3} className={classes.fragment}>
        <AddEnterpriseForm />
      </Paper>
      <Paper elevation={3} className={classes.fragment}>
        <EnterprisesList />
      </Paper>
    </React.Fragment>
  );
};
