import React from "react";
import "./EntreprisesCarrousel.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useFetchBannieresPub } from "./BannierePubSlice";

import { Link } from "react-router-dom";
import { useFetchInfosGlobales } from "./InfosGlobalesSlice";

export const EntreprisesCarrousel = () => {
  const fetchWrapper = useFetchBannieresPub();
  const { data } = useFetchInfosGlobales();
  const bannieres = fetchWrapper?.data;

  return (
    <div className="carousel-container-custom">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
        showThumbs={false}
        transitionTime={1000}
        showStatus={false}
        dynamicHeight={false}
      >
        {bannieres
          .filter((ban) => ban.is_shown)
          .map((ban) => (
            <Link key={ban?.id} to={"grilleEntreprises/" + ban?.enterprise}>
              <div id={ban?.id}>
                <img src={ban?.banniere} />
              </div>
            </Link>
          ))}
        {
          <Link key={-10} to={"/grilleEntreprises"}>
            <div id={-10}>
              <img src={data[0]?.banniere_default} />
            </div>
          </Link>
        }
      </Carousel>
    </div>
  );
};
