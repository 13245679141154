import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { API } from "../../api/rest";
import { STATUS } from "../../constants/rest";
import { useFetchData } from "../../api/hooks";

const partenairesAdapter = createEntityAdapter();

const initialState = partenairesAdapter.getInitialState({
  fetchStatus: STATUS.IDLE,
  error: null,
});

const fetch = createAsyncThunk(
  "enterprises/partenaires/fetch",
  async (_, thunkAPI) => {
    return API.fetchPartenaires()
      .then((response) => response.data)
      .catch((error) => thunkAPI.rejectWithValue(error));
  }
);
export { fetch as fetchPartenaires };

const partenairesSlice = createSlice({
  name: "partenaires",
  initialState: initialState,
  reducers: {
    upsertOne: partenairesAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.fetchStatus = STATUS.PENDING;
    });
    builder.addCase(fetch.fulfilled, (state, action) => {
      state.fetchStatus = STATUS.FULFILLED;
      partenairesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetch.rejected, (state, action) => {
      state.fetchStatus = STATUS.FAILED;
      state.error = action.payload;
      console.log(action.payload);
    });
  },
});
export const partenairesActions = partenairesSlice.actions;

const partenairesSelector = partenairesAdapter.getSelectors(
  (state) => state.partenaires
);
export const { selectAll: selectAllPartenaires } = partenairesSelector;

const selectFetchStatus = (state) => state.partenaires.fetchStatus;
export { selectFetchStatus as selectPartenairesFetchStatus };

const selectFetchError = (state) => state.partenaires.fetchError;
export { selectFetchError as selectPartenairesFetchError };

export default partenairesSlice.reducer;

export const useFetchPartenaires = () =>
  useFetchData(
    partenairesSelector.selectAll,
    selectFetchStatus,
    selectFetchError,
    fetch(),
    true
  );
