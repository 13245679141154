import React, { FC, useEffect, useState } from "react";
import { Email } from "./types";
import { Button, makeStyles, TextField } from "@material-ui/core";
import { emailRegex } from "../../helpers/utils";
import { API } from "../../api/rest";
import { useSnackbar } from "notistack";

const useStyle = makeStyles((theme) => ({
  form: {
    margin: "10px",
  },
  fromField: {
    marginRight: theme.spacing(5),
  },
  messageField: {
    width: "100%",
  },
  sendButton: {
    marginTop: "10px",
    marginLeft: "50px",
    justifySelf: "center",
    alignSelf: "center",
  },
}));

export const EmailForm: FC<{ email: Email }> = (props) => {
  const [email, setEmail] = useState(props.email);
  const [emailError, setEmailError] = useState("");
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setEmail(props.email);
  }, [props.email]);

  const validateFields = () => {
    const re = emailRegex;
    if (!re.test(email.to)) {
      setEmailError("Please enter a valid email address.");
      return false;
    } else {
      setEmailError("");
    }

    return true;
  };

  const onSendClicked = () => {
    if (validateFields()) {
      API.sendEmail(email)
        .then(() => {
          enqueueSnackbar("Email envoyé avec succès.", { variant: "success" });
        })
        .catch(() => {
          enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
        });
    }
  };

  return (
    <form className={classes.form}>
      <TextField
        className={classes.fromField}
        id="from"
        label="from"
        value={email.from}
        onChange={(event) => setEmail({ ...email, from: event.target.value })}
      />
      <TextField
        id="to"
        label="to"
        value={email.to}
        onChange={(event) => setEmail({ ...email, to: event.target.value })}
        error={emailError !== ""}
        helperText={emailError}
      />
      <br />
      <TextField
        id="subject"
        label="subject"
        value={email.subject}
        onChange={(event) =>
          setEmail({ ...email, subject: event.target.value })
        }
      />
      <br />
      <TextField
        className={classes.messageField}
        id="message"
        label="message"
        value={email.message}
        onChange={(event) =>
          setEmail({ ...email, message: event.target.value })
        }
        multiline
      />
      <br />
      <div className={classes.sendButton}>
        <Button className={classes.sendButton} onClick={onSendClicked}>
          Send
        </Button>
      </div>
    </form>
  );
};
