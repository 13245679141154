import React, { useEffect, useState } from "react";
import { STATUS } from "../../constants/rest";
import { API } from "../../api/rest";
import { EnterpriseYearly } from "../../features/enterprisesYearly/types";
import { AxiosError, AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { TextField, Typography } from "@material-ui/core";
import { EditButton } from "../EditButton";
import { enterpriseYearlyActions } from "../../features/enterprisesYearly/enterprisesYearlySlice";
import { useDispatch } from "react-redux";
import { OptionTree } from "../../features/options/optionsSlice";

export const ChooseOptionsTree: React.FC<{
  enterpriseYearly: EnterpriseYearly;
}> = (props) => {
  const [options, setOptions] = useState<OptionTree[]>([]);
  const [enterpriseYearly, setEnterpriseYearly] = useState(
    props.enterpriseYearly
  );

  const { enqueueSnackbar } = useSnackbar();

  const toggleOption = (optionId: number) => {
    if (enterpriseYearly.options_chosen.includes(optionId)) {
      setEnterpriseYearly({
        ...enterpriseYearly,
        options_chosen: enterpriseYearly.options_chosen.filter(
          (option) => optionId !== option
        ),
      });
    } else {
      setEnterpriseYearly({
        ...enterpriseYearly,
        options_chosen: enterpriseYearly.options_chosen.concat([optionId]),
      });
    }
  };

  const RootOption: React.FC<{ optionTree: OptionTree; depth: number }> = (
    props
  ) => {
    const isTerminal = props.optionTree.is_terminal_node;

    const labelId = `check-list-item-${props.optionTree.id}-label`;

    if (isTerminal) {
      return (
        <div>
          <ListItem
            key={props.optionTree.id}
            role="listitem"
            button
            onClick={() => {
              toggleOption(props.optionTree.id);
            }}
            disabled={!editMode}
          >
            <ListItemIcon>
              <Checkbox
                checked={enterpriseYearly.options_chosen.includes(
                  props.optionTree.id
                )}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>

            <ListItemText id={labelId}>
              {props.optionTree.name} - {props.optionTree.display_price}€
              <div style={{ fontStyle: "italic" }}>
                {props.optionTree.description}
              </div>
            </ListItemText>
          </ListItem>
        </div>
      );
    }

    if (props.optionTree.children.length === 0) {
      return null;
    }

    return (
      <div style={{ marginLeft: props.depth.toString() + "rem" }}>
        <div>
          <div style={{ textTransform: "uppercase" }}>
            {props.optionTree.name}
          </div>
          <div style={{ fontStyle: "italic" }}>
            {props.optionTree.description}
          </div>
        </div>
        {props.optionTree.children.map((option) => {
          return <RootOption optionTree={option} depth={props.depth + 1} />;
        })}
      </div>
    );
  };

  useEffect(() => {
    API.fetchOptionsTree(props.enterpriseYearly.id)
      .then((result: AxiosResponse<OptionTree[]>) => {
        setOptions(result.data);
      })
      .catch(() => {
        enqueueSnackbar("Une erreur s'est produite.", { variant: "error" });
      });
  }, [enqueueSnackbar, props.enterpriseYearly.id]);

  const [updateStatus, setUpdateStatus] = useState(STATUS.IDLE);
  const [updateErrors, setUpdateErrors] = useState({});

  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

  const handleEditButtonClicked = () => {
    setEditMode(!editMode);
  };

  const handleCancelButtonClicked = () => {
    setEnterpriseYearly(props.enterpriseYearly);
    setEditMode(false);
    setUpdateErrors({});
  };

  const handleSaveButtonClicked = () => {
    if (updateStatus !== STATUS.PENDING) {
      setUpdateStatus(STATUS.PENDING);

      let delta = {
        id: props.enterpriseYearly.id,
        options_chosen: enterpriseYearly.options_chosen,
        installation_remarks: enterpriseYearly.installation_remarks,
      };

      API.updateEnterpriseYearly(delta)
        .then((response: AxiosResponse) => {
          setUpdateStatus(STATUS.FULFILLED);
          setUpdateErrors({});
          setEditMode(false);

          dispatch(enterpriseYearlyActions.upsertOne(enterpriseYearly));
        })
        .catch((error: AxiosError) => {
          setUpdateStatus(STATUS.FAILED);
          setUpdateErrors(error.response?.data);
          console.log(updateErrors);
        });
    }
  };

  if (enterpriseYearly.status < 1) {
    return (
      <Typography variant={"h5"}>
        Vous devez remplir vos informations avant de pouvoir sélectionner des
        options.
      </Typography>
    );
  }
  if (enterpriseYearly.is_devis_accepted) {
    // TODO: Afficher uniquement les options choisis
    return (
      <>
        {options.map((option) => {
          return <RootOption optionTree={option} depth={1} />;
        })}
        {enterpriseYearly?.installation_remarks && (
          <Typography variant={"h5"}>
            Remarques: {enterpriseYearly.installation_remarks}
          </Typography>
        )}
        <Typography variant={"h5"}>
          Vous ne pouvez plus changer les options une fois le devis accepté.
        </Typography>
      </>
    );
  } else {
    return (
      <>
        {options.map((option) => {
          return <RootOption optionTree={option} depth={1} />;
        })}
        <TextField
          key={"useless-key"}
          id={"installation-remarks-field"}
          style={{ margin: "7px" }}
          variant={"outlined"}
          label={"Remarques concernant l'installation"}
          multiline
          fullWidth
          disabled={!editMode}
          value={enterpriseYearly.installation_remarks}
          onChange={(event) => {
            setEnterpriseYearly({
              ...enterpriseYearly,
              installation_remarks: event.target.value,
            });
          }}
        />
        <EditButton
          editMode={editMode}
          onEdit={handleEditButtonClicked}
          onSave={handleSaveButtonClicked}
          onCancel={handleCancelButtonClicked}
        />

        <div style={{ fontStyle: "italic", padding: "20px" }}>
          Si des options ou des quantités que vous souhaitez n'apparaissent pas
          ci-dessus, veuillez contacter un membre du Forum Trium en lui
          indiquant les options voulues.
        </div>
      </>
    );
  }
};
