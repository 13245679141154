import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../api/rest";

const initialState = {
  loginRequestStatus: "idle",
  fetchUserStatus: "idle",
  isLoggedIn: false,
  loginError: null,
  fetchCurrentUserError: null,
  currentUser: null,
  token: "",
};

export const fetchCurrentUser = createAsyncThunk(
  "API/fetchCurrentUser",
  async (_, thunkAPI) => {
    return API.fetchCurrentUser()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        thunkAPI.dispatch(logout());
        return thunkAPI.rejectWithValue({
          code: error.response.status,
          message: error.message,
        });
      });
  }
);

export const login = createAsyncThunk("API/login", (data, thunkAPI) => {
  API.setToken(data.token);
  // thunkAPI.dispatch(fetchCurrentUser());
  return data;
});

export const logout = () => (dispatch) => {
  // There is no redundancy here! When resetting the store, the user would be reloaded if we didn't voided it before.

  API.nullifyToken();
  dispatch(nullifyCurrentUser());
  dispatch({ type: "RESET_STORE" });
};

export const setAPIToken = () => (dispatch, getState) => {
  const state = getState();
  const token = state.API.token;

  API.setToken(token);
};

export const apiSlice = createSlice({
  name: "API",
  initialState: initialState,
  reducers: {
    nullifyCurrentUser: (state) => {
      state.currentUser = null;
      state.token = "";
      state.isLoggedIn = false;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.token = action.payload.token;
    },
    [fetchCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload;
    },
    [fetchCurrentUser.rejected]: (state, action) => {
      console.log(action.payload.message);
    },
  },
});

export const { nullifyCurrentUser, setToken } = apiSlice.actions;

export const selectLoginRequestStatus = (state) => state.API.loginRequestStatus;
export const selectIsLoggedIn = (state) => state.API.isLoggedIn;
export const selectLoginError = (state) => state.API.loginError;
export const selectCurrentUser = (state) => state.API.currentUser;

export default apiSlice.reducer;
