import "./UserIntro.css";

export const UserIntro = (props) => {
  return (
    <div className="user-intro-container">
      <div className="user-intro-infos-container">
        <div className="user-intro-name-container">
          <div className="user-intro-name">{props?.user?.first_name}</div>
          <div className="user-intro-name user-intro-last-name">
            {props?.user?.last_name}
          </div>
        </div>
        <div className="user-intro-ent-container">
          <div className="user-intro-ent">{props?.enterpriseYearly?.name}</div>
          <div className="user-intro-ent user-intro-year">
            {props?.enterpriseYearly?.year}
          </div>
        </div>
      </div>
      <div className="user-intro-disclaimer">
        Si les informations ne sont pas à jour après une sauvegarde, tentez de
        rafraichir la page.
      </div>
    </div>
  );
};
