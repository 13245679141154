import React from "react";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <div style={{ backgroundColor: "#ECFFFF", width: "100%", height: "90vh" }}>
      <p
        style={{
          textAlign: "center",
          color: "rgb(119, 189, 211)",
          padding: "0px",
          margin: "0px",
          paddingTop: "20vh",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        404 Not Found
      </p>

      <p
        style={{
          textAlign: "center",
          color: "gray",
          padding: "0px",
          margin: "0px",
          paddingTop: "10vh",
        }}
      >
        <Link to="/">Retour à la page d'accueil</Link>
      </p>
    </div>
  );
};
