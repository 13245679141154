// On part d'ici pour tous les composants qui necessitent le fetching d'epoch.
// L'epoch active est alors donnee comme props au sous-composant.
// Cela permet d'eviter de devoir fetch les epochs partouts.

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  epochsSelector,
  fetchEpochs,
  selectEpochsFetchStatus,
  selectSelectedEpoch,
} from "./epochsSlice";
import { STATUS } from "../../constants/rest";
import { EpochSelector } from "./EpochSelector";
import { EpochStatus } from "./EpochStatus";
import { contentFromStatus } from "../../helpers/displayHelpers";
import { EnterprisesYearlyList } from "../enterprisesYearly/EnterprisesYearlyList";

export const EpochDashboard = () => {
  const dispatch = useDispatch();
  const activeEpoch = useSelector(selectSelectedEpoch);
  const fetchStatus = useSelector(selectEpochsFetchStatus);
  const epochs = useSelector(epochsSelector.selectAll);

  useEffect(() => {
    if (fetchStatus === STATUS.IDLE) {
      dispatch(fetchEpochs());
    }
  });

  return contentFromStatus(fetchStatus, () => {
    return (
      <section>
        <EpochSelector />
        <EpochStatus epoch={activeEpoch} />
        {/*<CreateLinkForm epoch={activeEpoch} />*/}
        {/*<LinksList epoch={activeEpoch}/>*/}
        <EnterprisesYearlyList />
      </section>
    );
  });
};
