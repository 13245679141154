import React, { FC } from "react";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../../features/api/apiSlice";

export const LogoutButton: FC = () => {
  const dispatch = useDispatch();

  const onLogoutButtonClicked = () => {
    dispatch(logout());
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      disableElevation
      onClick={onLogoutButtonClicked}
    >
      Déconnexion
    </Button>
  );
};
